
.inner {
	display: block;
	max-width: 1130px;
	padding: 0 15px;
	margin: 0 auto;
}


.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a{
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
}


.pc{
	@include mq(){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-ipd{
		@include mq(ipd-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}


//ttl
.ttlcomp{
	&-01{
		display: block;
		text-align: center;
		font-family: $font-mincho;
		margin-bottom: 22px;
		span{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			line-height: 1;
			color: white;
			width: 120px;
			height: 120px;
			background: #404040;
			border-radius: 50%;
			@include font-size(66);
			font-weight: 500;
			@include mq(){
				width: 69px;
				height: 69px;
				@include font-size(35);
			}
		}
		img{
			height: auto !important;
		}
	}
	&-02{
		font-family: $font-mincho;
		@include font-size(35);
		font-weight: 600;
		text-align: center;
		@include palt();
		letter-spacing: 3px;
		margin-bottom: 24px;
		@include mq(){
			@include font-size(21);
			margin-bottom: 17px;
		}
	}
	&-03{
		text-align: center;
		margin-bottom: 20px;
		@include mq(){
			margin-bottom: 11px;
		}
		span{
			font-family: $font-mincho;
			@include font-size(50);
			font-weight: 600;
			display: block;
			@include palt();
			@include mq(){
				@include font-size(28);
			}
		}
		small{
			font-family: $font-mincho;
			@include font-size(25);
			font-weight: 600;
			display: block;
			letter-spacing: 1px;
			@include mq(){
				@include font-size(15);
				margin-bottom: 3px;
			}
		}
	}
	&-04{
		font-family: $font-mincho;
		@include font-size(40);
		font-weight: 600;
		text-align: center;
		margin-bottom: 47px;
		@include mq(){
			@include font-size(22.5);
			margin-bottom: 30px;
		}
	}
}

//text
.txt{
	&-basic{
		line-height: 1.69444444;
		@include font-size(18);
		font-weight: 500;
		@include mq(){
			@include font-size(16);
		}
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-left {
		text-align: left;
	}
}



//btn
.btn {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	@include font-size(20);
	font-family: $font-mincho;
	line-height: 1;
	width: 240px;
	height: 50px;
	font-weight: 600;
	margin: 0 auto;
	background: #404040;
	color: white;
	cursor: pointer;
	@include mq(){
		@include font-size(13);
		width: 180px;
		height: 38px;
	}
	&-white{
		background: white;
		color: #404040;
	}
	&-comp01{
// 		margin-top: 10px;
		font-family: $font-mincho;
		max-width: 530px;
		height: 60px;
		width: 100%;
		background: #fff;
		@include font-size(24);
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #404040;
		position: relative;
		@include palt();
		letter-spacing: 1.5px;
		@include mq(){
			@include font-size(13);
			max-width: 290px;
			height: 38px;
		}
		&:after{
			content: "";
			display: block;
			width: 25px;
			height: 25px;
			background: url("../img/common/ico-arrow.png") no-repeat center/cover;
			position: absolute;
			top: calc(50% - 12px);
			right: 40px;
			@include mq(){
				width: 15px;
				height: 15px;
				top: calc(50% - 7.5px);
				right: 10px;
			}
		}
	}
	&-list{
		font-family: $font-mincho;
		@include font-size(15);
		font-weight: 700;
		max-width: 155px;
		width: 100%;
		margin-left: auto;
		margin-right: 0;
		height: 34px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #404040;
		@include mq(){
			max-width: 165px;
			height: 38px;
			@include font-size(13);
		}
		span{
			position: relative;
			display: flex;
			align-items: center;
			&:before{
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background: url("../img/common/ico-arrow-left.png") no-repeat center/cover;
				position: relative;
				margin-right: 10px;
			}
		}
	}
	&-submit{
		font-family: $font-mincho;
		@include font-size(17);
		font-weight: 700;
		max-width: 175px;
		width: 100%;
		margin: 0 auto;
		height: 45px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #404040;
		border: none;
		outline: 0;
		border-radius: 0;
		appearance: none;
		cursor: pointer;
		transition: 0.3s ease all;
		&:hover{
			opacity: 0.7;
			transition: 0.3s ease all;
		}
		@include mq(){
			max-width: 165px;
			height: 38px;
			@include font-size(13);
		}
		span{
			position: relative;
			display: flex;
			align-items: center;
			&:after{
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background: url("../img/common/ico-arrow.png") no-repeat center/cover;
				position: relative;
				margin-left: 10px;
			}
		}
	}
}




//form input
.form-control {
	display: block;
	width: 100%;
	height: 45px;
	background: white;
	border: 1px solid #ccc;
	font-weight: 500;
	padding: 5px 10px;
	@include font-size(15);
	&:focus {
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
	}
}

.bg{
	&-white{
		background: white !important;
	}
	&-orange{
		background: #e66031 !important;
	}
	&-light-blue{
		background: #b9d1f1 !important;
	}
	&-yellow{
		background: #eced4f !important;
	}
	&-violet{
		background: #cba4d2 !important;
	}
	&-pink{
		background: #fde6e5 !important;
	}
}


.sec-banner{
	padding: 67px 0 75px;
	background: url("../img/common/bg-banner.jpg") no-repeat center/cover;
	@include mq(){
		padding: 37px 0;
	}
	.banner-ttl{
		text-align: center;
		margin-bottom: 30px;
		@include mq(){
			margin-bottom: 20px;
			img{
				width: 223.5px;
			}
		}
		span{
			font-family: $font-mincho;
			@include font-size(32);
			font-weight: 600;
			color: #fff;
			display: block;
			letter-spacing: 1px;
			margin-bottom: 6px;
			@include mq(){
				@include font-size(18);
			}
		}
		small{
			font-family: $font-mincho;
			color: #fff;
			@include font-size(18);
			display: block;
			@include mq(){
				@include font-size(12.5);
			}
		}
	}
	.btn-group{
		li{
			margin-bottom: 10px;
			@include mq(){
				margin-bottom: 8px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			&:first-of-type{
				.btn{
					span{
						position: relative;
						left: -18px;
						@include mq(){
							left: -12px;
						}
					}
				}
			}
		}
	}
	.ico-banner{
		bottom: -110px;
		left: -188px;
		position: absolute;
		@include mq(){
			left: auto;
			bottom: auto;
			top: 0;
			right: 0;
			transform: translate(16%, -70%);
		}
	}
	.banner-wp{
		position: relative;
		max-width: 530px;
		margin: 0 auto;
		@include mq(){
			max-width: 290px;
		}
	}
}


//fade up
.fade-up{
	opacity: 0;
	transform: translate(0px, 120px);
	transition: transform 2s, opacity 1.5s;
	&.active{
		transform: translate(0px, 0px);
		opacity: 1;
	}
}
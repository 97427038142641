.sec-room{
	background: #eced4f;
	padding: 70px 0 35px;
	z-index: 2;
	@include mq(){
		padding: 36px 0 20px;
	}
	.heading-block{
		margin-bottom: 36px;
		@include mq(){
			margin-bottom: 24px;
		}
		.ttlcomp-02{
			margin-bottom: 5px;
			@include mq(){
				margin-bottom: 0;
			}
		}
		.txt-basic{
			font-weight: 500;
			@include mq(){
				@include font-size(13);
			}
		}
	}

	&:after{
		content: "";
		display: block;
		width: 100%;
		height: 8vw;
		background: url(../img/kyo/after-yellow.png) no-repeat center/cover;
		position: absolute;
		top: 100%;
		left: 0;
		@include mq(){
			display: none;
		}
	}
}

.room-block{
	.item{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 70px;
		@include mq(sm){
			padding: 0 3px 0 12px;
			margin-bottom: 47px;
		}
		&__info{
			padding-left: 50px;
			padding-right: 50px;
			padding-top: 20px;
			width: 50%;
			@include mq(ipd){
				padding-top: 0;
				padding-left: 16px;
				padding-right: 18px;
			}
			@include mq(sm){
				width: 100%;
				order: 2;
			}
			.txt-basic{
				line-height: 1.6;
				margin-bottom: 21px;
				@include mq(){
					@include font-size(12);
					line-height: 1.7;
				}
			}
			.img-sub{
				margin: 0 -18px 27px -18px;
				text-align: center;
				@include mq(ipd){
					margin: 0 auto 20px;
				}
				@include mq(){
					order: 2;
					margin: 0 auto;
				}
			}
			
			
			.flex-room{
				@include mq(){
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
		&__img{
			width: 50%;
			@include mq(sm){
				order: 1;
				width: 100%;
				margin-bottom: -23px;
			}
		}
		
		&:nth-child(even){
			.item__info{
				order: 2;
			}
			.item__img{
				order: 1;
			}
		}
		&-other{
			.item__info{
				width: calc(100% - 54.8%);
				padding-right: 0;
				@include mq(){
					width: 50%;
				}
				@include mq(sm){
					width: 100%;
				}
			}
			.item__img{
				width: 54.8%;
				@include mq(){
					width: 50%;
					padding: 0 15px;
				}
				@include mq(sm){
					width: 100%;
					
				}
			}
		}
		&:nth-child(n + 4){
			margin-left: -13px;
			margin-bottom: 52px;
			@include mq(){
				margin-left: 0;
				align-items: center;
			}
			@include mq(sm){
				margin-left: 0;
				margin-bottom: 20px;
			}
			.item__img{
				width: 40%;
				order: 1;
				position: relative;
				@include mq(sm){
					width: 100%;
					padding-right: 80px;
					padding-left: 15px;
					margin-bottom: 17px;
				}
				.ttlcomp-03{
					@include mq(sm){
						text-align: left;
						position: absolute;
						top: 28px;
						right: 26px;
					}
					span{
						@include mq(){
							@include font-size(20);
						}
					}
				}
			}
			.item__info{
				order: 2;
				width: calc(100% - 40%);
				padding-left: 35px;
				@include mq(ipd){
					padding-left: 15px;
				}
				@include mq(sm){
					width: 100%;
					padding-left: 0;
				}
				.txt-basic{
					margin-bottom: 0;
					@include mq(){
						padding-left: 15px;
						margin-bottom: 18px;
					}
				}
				.img-sub{
					margin-bottom: 20px;
				}
				.flex{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					.col-left{
						width: 54%;
						padding-right: 30px;
						@include mq(ipd){
							width: 100%;
						}
						@include mq(){
							width: 100%;
							padding-right: 0;
						}
					}
					.col-right{
						width: 46%;
						@include mq(ipd){
							width: 100%;
							display: flex;
							align-items: flex-start;
						}
						@include mq(){
							padding-left: 15px;
							padding-right: 7px;
						}
						.img-sub{
							@include mq(ipd){
								width: 49%;
							}
							@include mq(){
								width: 50%;
								padding-left: 7px;
								margin-top: -4px;
							}
						}
						.room-info{
							@include mq(ipd){
								width: 49%;
							}
							@include mq(){
								width: 50%;
								padding: 10px 12px 6px;
								line-height: 1.7;
							}
						}
					}
				}
			}
		}
		&:last-of-type{
			margin-bottom: 0;
			.item__img{
				@include mq(sm){
					padding-right: 24px;
					padding-left: 90px;
				}
				.ttlcomp-03{
					@include mq(){
						position: absolute;
						left: 16px;
						top: 34px;
						right: auto;
					}
				}
			}
			.item__info{
				.flex{
					.col-right{
						.img-sub{
							@include mq(){
								order: 1;
								padding-left: 0;
							}
						}
						.room-info{
							@include mq(){
								order: 2;
							}
						}
					}
				}
			}
		}
	}
	
	.room-info{
		background: #fff;
		@include font-size(18);
		font-weight: 500;
		line-height: 1.56;
		padding: 10px 20px;
		@include mq(){
			order: 1;
			@include font-size(12);
			margin-bottom: 20px;
		}
	}
}
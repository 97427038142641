.sec-policy{
	background: #fde6e5;
	padding-bottom: 105px;
	@include mq(){
		padding-bottom: 43px;
	}
	.ttlcomp-04{
		margin-bottom: 8px;
	}
	.txt-intro{
		font-family: $font-mincho;
		@include font-size(17);
		font-weight: 600;
		line-height: 1.6;
		margin-bottom: 46px;
		@include mq(){
			margin-bottom: 34px;
			@include font-size(15);
			line-height: 1.75;
		}
	}
}

.policy-content{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	font-family: $font-mincho;
	@include mq(){
		padding: 0 25px;
	}
	.item{
		@include font-size(15);
		font-weight: 500;
		line-height: 1.75;
		margin-bottom: 27px;
		@include mq(){
			line-height: 2;
		}
		&:last-of-type{
			margin-bottom: 0;
			@include mq(){
				line-height: 1.8;
			}
		}
		strong{
			@include font-size(18);
			font-weight: 600;
			display: block;
			@include mq(){
				@include font-size(17);
			}
		}
		.tel{
			@include font-size(30);
			font-weight: 500;
			color: #404040;
			@include mq(){
				@include font-size(25);
			}
		}
		span{
			@include font-size(20);
			line-height: 1.5;
			@include mq(){
				@include font-size(17);
			}
			a{
				font-weight: 600;
				color: #404040;
				text-decoration: underline;
			}
		}
	}
}

.bg-violet{
	background: #cba4d2;
	.list-item{
		.item{
			&__img{
				&:before{
					background: url("../img/den/before.png") no-repeat center/cover;
				}
			}
			&__info{
				background: #cba4d2;
			}
		}
	}
}

.page-den_index{
	.sec-hot-spring{
		.list-item{
			.item{
				@include mq(){
					padding-top: 36px;
				}
				&__heading{
					margin-bottom: -10px;
					.ttlcomp-02{
						@include mq(){
							margin-bottom: 30px;
						}
					}
					.txt-basic{
						@include font-size(16);
						@include mq(){
							text-align: center;
						}
					}
				}
				&__info{
					padding-top: 73px;
					padding-bottom: 0;
					margin-bottom: -35px;
					@include mq(){
						padding-top: 37px;
						margin-bottom: -10px;
					}
					.wp-inner{
						@include mq(){
							padding: 0 15px;
						}
					}
					.txt-basic{
						@include font-size(16);
						line-height: 1.75;
						position: relative;
						z-index: 1;
						@include mq(){
							text-align: center;
						}
						p{
							margin-bottom: 5px;
						}
						a{
							text-decoration: underline;
							color: #404040;
						}
					}
				}
			}
		}
	}
	.sec-mv-page .mv-heading .ico{
		@include mq(md-min){
			width: 183px;
			left: auto;
			right: -232px;
			top: 0;
		}
		@include mq(){
			top: -33px;
			left: auto;
			right: 13px;
			width: 79px;
		}
	}
	.list-item{
		margin-bottom: 0;
	}
}

.sec-five-senses{
	padding-top: 75px;
	margin-bottom: 101px;
	@include mq(){
		padding-top: 40px;
	}
	@include mq(sm){
		padding-bottom: 35px;
		margin-bottom: 0;
	}
	&:after{
		content: "";
		display: block;
		width: 100%;
		height: 7.4vw;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -7.4vw;
		background: url("../img/den/before.png") no-repeat center/cover;
	}
	.heading{
		text-align: center;
		margin-bottom: 18px;
		.ttlcomp-02{
			display: inline-block;
			@include mq(){
				@include font-size(25);
				margin-bottom: 6px;
			}
		}
		.ico{
			position: absolute;
			top: 90px;
			right: auto;
			margin-left: 14px;
			@include mq(){
				top: -30px;
				right: auto;
				margin-left: -14px;
			}
		}
	}
}

.list-senses{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -12.5px -20px -12.5px;
	@include mq(){
		margin: 0;
	}
	.item{
		width: 33.33%;
		padding: 0 12.5px;
		margin-bottom: 20px;
		@include mq(sm){
			width: 100%;
			padding: 0;
			margin-bottom: 38px;
		}
		&:last-of-type{
			@include mq(){
				margin-bottom: 0;
			}
		}
		&__ttl{
			text-align: center;
			margin-bottom: 24px;
			@include mq(){
				margin-bottom: 12px;
			}
			span{
				font-family: $font-mincho;
				@include font-size(50);
				font-weight: 600;
				display: block;
				letter-spacing: 2px;
				margin-bottom: 10px;
				@include mq(){
					@include font-size(25);
				}
			}
			small{
				@include font-size(18);
				font-weight: 500;
				display: block;
				@include mq(){
					@include font-size(16);
				}
			}
		}
		&__img{
			//max-width: 160px;
			width: 100%;
			margin: 0 auto;
			//border-radius: 50%;
			overflow: hidden;
			@include mq(){
				max-width: 162px;
			}
			img{
				display: block;
				margin: 0 auto;
			}
		}
	}
}


.sec-den-bottom{
	padding-top: 90px;
	padding-bottom: 108px;
	@include mq(){
		padding-top: 48px;
	}
	@include mq(sm){
		padding-bottom: 23px;
	}
	.ttlcomp-02{
		@include mq(){
			margin-bottom: 30px;
		}
	}
	.txt-des{
		@include font-size(16);
		font-weight: 500;
		line-height: 1.75;
		margin-bottom: 95px;
		@include mq(){
			line-height: 1.7;
			letter-spacing: -1px;
			margin-bottom: 40px;
		}
	}
	.list-den{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
		@include mq(){
			margin: 0 -10px;
		}
		.item{
			width: 33.33%;
			padding: 0 12px;
			margin-bottom: 15px;
			@include mq(sm){
				width: 50%;
				padding: 0 10px;
			}
			.img{
				border-radius: 50%;
				overflow: hidden;
			}
		}
	}
}

.page-kyo_index{
	& + footer{
		.sec-banner {

			@include mq(md-min){
				padding: 195px 0 75px;
			}
		}
	}
}
.page-syoku_index{
	.mv-heading {
		.ico{
			left: auto;
			right: -61%;
			top: 100px;
			@include mq(){
				right: -8px;
				left: 11px;
				top: -30px;
				transform: translateX(0);
			}
		}
	}
}
.food{
	&-block{
		background: #e66031;
		position: relative;
		padding: 70px 0 33px;
		z-index: 2;
		@include mq(){
			padding: 37px 0 0;
		}
		&:after{
			content: "";
			position: absolute;
			top: calc(100% - 1px);
			left: 0;
			right: 0;
			height: 7.5vw;
			background: url(../img/syoku/af.png) no-repeat;
			background-size: 100%;
			@include mq(){
				height: 11vw;
				background-size: cover;
				background-position: bottom center;
			}
		}
		&__ttl{
			display: block;
			text-align: center;
			font-family: $font-mincho;
			@include font-size(35);
			font-weight: 600;
			color: white;
			margin-bottom: 47px;
			@include mq(){
				@include font-size(22);
				margin-bottom: 24px;
			}
			span{
				position: relative;
				.ico{
					position: absolute;
					top: 0;
					&-left{
						left: -195px;
						top: -20px;
						@include mq(){
							left: -70px;
							top: 6px;
							width: 92px;
						}
					}
					&-right{
						right: -169px;
						top: 25px;
						@include mq(){
							right: -68px;
							top: -71px;
							width: 73px;
						}
					}
				}
			}
		}
		&__list{
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
			.item{
				width: 33.333%;
				padding: 0 15px;
				text-align: center;
				@include font-size(18);
				line-height: 1.55555;
				font-weight: 500;
				@include mq(){
					@include font-size(16);
					line-height: 1.71875;
					width: 100%;
					margin-bottom: 31px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.txt{
				margin-bottom: 16px;
				@include mq(){
					margin-bottom: 20px;
				}
			}
			.img{
				img{
					width: 100%;
					border-radius: 50%;
					@include mq(){
						width: 175px;
					}
				}
			}
			.status{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				width: 120px;
				height: 120px;
				border: 1px solid white;
				border-radius: 50%;
				font-family: $font-mincho;
				@include font-size(25);
				color: white;
				line-height: 1;
				font-weight: 600;
				white-space: nowrap;
				margin: 0 auto;
				-webkit-writing-mode: vertical-rl;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				margin-bottom: 20px;
				@include mq(){
					width: 70px;
					height: 70px;
					@include font-size(16);
					margin-bottom: 7px;
				}
			}
			.ttl{
				display: block;
				@include font-size(50);
				font-family: $font-mincho;
				font-weight: 600;
				margin-bottom: 10px;
				@include mq(){
					@include font-size(26);
					margin-bottom: 6px;
				}
			}
		}
	}
	&-banner{
		position: relative;
		&__img{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.slick-arrow{

			}
			.slick-list{
				width: 100%;
			}
			img{
				width: 100%;
				max-height: 900px;
				@include ob;
			}
		}
		&__note{
			background: rgba(black, 0.5);
			color: white;
			@include font-size(18);
			font-weight: 500;
			line-height: 1.55555;
			text-align: center;
			padding: 41px 15px 56px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			@include mq(){
				@include font-size(14);
				padding: 15px 15px 26px;
				line-height: 1.75;
			}
			.ttl{
				display: block;
				text-align: center;
				font-family: $font-mincho;
				@include font-size(35);
				font-weight: 600;
				letter-spacing: 1px;
				margin-bottom: 18px;
				height: 0;
				@include mq(){
					@include font-size(22);
					margin-bottom: 14px;
				}
				span{
					position: relative;
					img{
						opacity: 0;
					}
					.ico{
						position: absolute;
						img{
							opacity: 1;
						}
						&-left{
							top: -143px;
							left: -200px;
							@include mq(){
								top: -110px;
								left: -84px;
								width: 107px;
							}
						}
						&-right{
							top: -149px;
							right: -213px;
							@include mq(){
								top: -99px;
								right: -90px;
								width: 77px;
							}
						}
					}
				}
			}
			p{
				margin-bottom: 28px;
				@include mq(){
					margin-bottom: 23px;
				}
			}
			&-02{
				background: rgba(white, 0.8);
				color: #404040;
			}
		}
		&__ttl{
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 40%;
			transform: translateY(-50%);
			@include mq(){
				top: 33%;
			}
		}
		&__dost{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			position: relative;
			margin-bottom: 20px;
			.dost-banner{
				position: relative;
			}
			.slick-dots{
				position: static;
				li{
					@include mq(){
						margin: 0 3px;
					}
					button{
						&:before{
							@include mq(){
								width: 9px;
								height: 9px;
							}
						}
					}
					&.slick-active{
						button{
							&:before{
								background: #404040;
							}
							&:after{
								content: "";
								width: 6px;
								height: 6px;
								background: #404040;
								border-radius: 50%;
								position: absolute;
								left: 3px;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								z-index: 1;
								margin: 0 auto;
								@include mq(){
									width: 5px;
									height: 5px;
									top: 2px;
									left: 0;
									transform: translateY(0);
								}
							}
						}
					}
				}
			}
			.slick-arrow{
				transform: translate(0, 0);
				top: 8px;
				width: 13px;
				height: 23px;
				@include mq(){
					top: 9px;
					width: 9px;
					height: 17px;
				}
				&:before{
					display: none !important;
				}
				&.slick-prev{
					background: url("../img/common/prev.png")no-repeat;
					background-size: 100%;
					left: -35px;
					@include mq(){
						left: -25px;
					}
				}
				&.slick-next{
					background: url("../img/common/next.png")no-repeat;
					background-size: 100%;
					right: -35px;
					@include mq(){
						right: -25px;
					}
				}
			}
			&-02{
				.slick-dots{
					position: static;
					li{
						button{
							&:before{
								background: #404040;
							}
						}
						&.slick-active{
							button{
								&:before{
									background: white;
								}
								&:after{
									background: white;
								}
							}
						}
					}
				}
				.slick-arrow{
					&.slick-prev{
						background: url("../img/common/prev-02.png")no-repeat;
						background-size: 100%;
					}
					&.slick-next{
						background: url("../img/common/next-02.png")no-repeat;
						background-size: 100%;
					}
					&.slick-disabled{
						pointer-events: none;
					}
				}
			}
		}
		&-02{
			.food-banner__img img{
				max-height: 850px;
			}
		}
	}
}
.show-popup{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background: rgba(black, 0.7);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	opacity: 0;
	visibility: visible;
	transition: ease 0.5s;
	.popup-block{
		display: block;
		max-width: 1012px;
		position: relative;
		background: #fde6e5;
		padding: 50px;
		@include mq(){
			padding: 25px 10px 10px;
		}
		&__close{
			cursor: pointer;
			width: 32px;
			position: absolute;
			top: 13px;
			right: 13px;
			@include mq(){
				width: 16px;
				top: 5px;
				right: 5px;
			}
		}
		&__img{
			display: none;
			&.active{
				display: block;
			}
			img{
				max-height: 80vh;
			}
		}
	}
	&.active{
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
}
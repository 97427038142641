.sec-news{
	padding-bottom: 98px;
	@include mq(){
		padding-bottom: 76px;
	}
}

.news-block{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	@include mq(){
		padding: 0 28px;
	}
	.item{
		margin-bottom: 77px;
		@include mq(){
			margin-bottom: 72px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		&__ttl{
			@include font-size(20);
			font-weight: bold;
			border-bottom: 1px solid #404040;
			padding-bottom: 4px;
			margin-bottom: 10px;
			@include mq(){
				@include font-size(17);
				margin-bottom: 7px;
			}
		}
		&__info{
			.date{
				@include font-size(15);
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 6px;
				@include mq(){
					margin-bottom: 1px;
				}
			}
		}
		&__content{
			@include font-size(15);
			font-weight: 500;
			line-height: 1.7;
			margin-bottom: 4px;
			@include mq(){
				height: 100px;
				overflow-y: hidden;
			}
		}
		.btn-group{
			.btn-comp01{
				max-width: 155px;
				height: 34px;
				@include font-size(15);
				font-weight: 700;
				margin-left: auto;
				margin-right: 0;
				@include mq(){
					max-width: 165px;
					height: 37px;
					@include font-size(13);
				}
				&:after{
					width: 15px;
					height: 15px;
					top: calc(50% - 7.5px);
					right: 16px;
					@include mq(){
						right: 24px;
					}
				}
				span{
					position: relative;
					left: -12px;
				}
			}
		}
	}
}

.post-detail-block{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	@include mq(){
		padding: 0 28px;
	}
	.post-ttl{
		@include font-size(20);
		font-weight: bold;
		border-bottom: 1px solid #404040;
		padding-bottom: 4px;
		margin-bottom: 10px;
		@include mq(){
			@include font-size(17);
			padding-bottom: 5px;
			margin-bottom: 5px;
		}
	}
	.date{
		@include font-size(15);
		font-weight: bold;
		letter-spacing: 2px;
		margin-bottom: 6px;
		@include mq(){
			margin-bottom: 0;
		}
	}
	.post-detail{
		@include font-size(15);
		line-height: 1.67;
		font-weight: 500;
		@include mq(){
			margin-bottom: 20px;
		}
	}
}
@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

body {
  color: #404040;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "Meiryo", "メイリオ", "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  position: relative;
  overflow: hidden;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

@media screen and (max-width: 834px) {
  #header {
    position: fixed;
  }
}

#header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  max-width: 100%;
}

@media screen and (min-width: 835px) {
  #header.full {
    top: -100px;
  }
  #header.full .logo {
    opacity: 0 !important;
    visibility: hidden;
    transition: all 0s ease;
  }
  #header.full .inner {
    max-width: 100%;
    padding: 0;
  }
  #header.full.fixed {
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    position: fixed;
    top: 0;
  }
}

#header .logo {
  width: 171px;
  display: block;
  position: relative;
}

@media screen and (max-width: 834px) {
  #header .logo {
    width: 112.5px;
    top: 5px;
  }
}

#header .logo .logo-02 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#header .header-show {
  height: 100px;
  background: #fde6e5;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  #header .header-show {
    height: 55px;
  }
}

#header .header-tel {
  background: #404040;
  width: 280px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
}

@media screen and (max-width: 834px) {
  #header .header-tel {
    width: 55px;
    height: 55px;
  }
}

#header .header-tel img {
  width: 216.5px;
}

@media screen and (max-width: 834px) {
  #header .header-tel img {
    width: 23px;
  }
}

#header .header-note {
  background: #404040;
  color: white;
  text-align: center;
  width: 100px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  font-weight: 500;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  #header .header-note {
    font-size: 1.4rem;
    width: 55px;
  }
}

#header .show-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #e6e6e6;
  display: none;
}

@media screen and (max-width: 834px) {
  #header .show-menu {
    padding: 9px 0 0;
  }
}

#header .show-menu .item {
  position: relative;
  border-bottom: 1px solid white;
}

#header .show-menu .item:last-child {
  border-bottom: none;
}

#header .show-menu .item a {
  display: block;
  font-size: 1.65rem;
  font-weight: 600;
  color: black;
  padding: 8px 30px;
}

@media screen and (max-width: 834px) {
  #header .show-menu .item a {
    font-size: 1.5rem;
    padding: 6px 16px;
  }
}

#header .show-menu .item a small {
  font-size: 1.25rem;
}

@media screen and (max-width: 834px) {
  #header .show-menu .item a small {
    font-size: 1.2rem;
  }
}

#header .show-menu .item a:hover {
  background: white;
  opacity: 1;
}

#header .show-menu .item-last {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 30px;
}

@media screen and (max-width: 834px) {
  #header .show-menu .item-last {
    padding: 8px 17px;
  }
}

#header .show-menu .item-last a {
  padding: 0;
  margin-right: 10px;
}

#header .show-menu .item-last a:hover {
  background: none;
  opacity: 0.7;
}

#header .show-menu .item-last .language {
  display: block;
  font-size: 1.5rem;
  background: #404040;
  color: white;
  line-height: 1;
  padding: 9px 18px;
  margin-right: 17px;
}

@media screen and (max-width: 834px) {
  #header .show-menu .item-last .language {
    font-size: 1.4rem;
    margin: 0 30px 15px 0;
  }
}

#header .show-menu .item-last .language:hover {
  background: #404040;
  opacity: 0.7;
}

#header .show-menu .item.active a {
  background: #fde6e5;
}

#header .mobile-icon {
  background: #404040;
  width: 100px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  z-index: 12;
}

@media screen and (max-width: 834px) {
  #header .mobile-icon {
    width: 55px;
  }
}

#header .mobile-icon span {
  width: 30px;
  height: 2px;
  background: #fde6e5;
  position: relative;
}

@media screen and (max-width: 834px) {
  #header .mobile-icon span {
    width: 22px;
  }
}

#header .mobile-icon span:before, #header .mobile-icon span:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fde6e5;
  content: '';
  transition: transform 0.3s;
  border-radius: 3px;
}

#header .mobile-icon span:before {
  transform: translateY(-250%);
  top: -5px;
}

@media screen and (max-width: 834px) {
  #header .mobile-icon span:before {
    top: -3px;
  }
}

#header .mobile-icon span:after {
  transform: translateY(250%);
  bottom: -5px;
}

@media screen and (max-width: 834px) {
  #header .mobile-icon span:after {
    bottom: -3px;
  }
}

#header .mobile-icon.mobile-close {
  background: #e6e6e6;
}

#header .mobile-icon.mobile-close span {
  background: none;
}

#header .mobile-icon.mobile-close span:before, #header .mobile-icon.mobile-close span:after {
  width: 100%;
  background: #404040;
}

#header .mobile-icon.mobile-close span:before {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  top: 2px;
}

#header .mobile-icon.mobile-close span:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
  bottom: -2px;
}

#header.top-page {
  top: -100px;
  transition: ease 1s;
}

#header.top-page .logo {
  opacity: 0;
  z-index: 9;
  transition: ease 3s;
}

#header.top-page .logo-01 {
  opacity: 0;
  visibility: hidden;
}

#header.top-page .logo-02 {
  opacity: 0;
  visibility: hidden;
  transition: ease 3.5s;
  transition-delay: 1.5s;
}

.is-hello.active .top-page {
  top: 0 !important;
}

.is-hello.active .top-page .logo {
  opacity: 1 !important;
}

.is-hello.active .top-page .logo-02 {
  opacity: 1 !important;
  visibility: visible !important;
}

.is-hello.active .sec-mv:after {
  opacity: 1 !important;
  visibility: visible !important;
}

.is-hello.active .mv-list .item img {
  opacity: 1 !important;
}

#footer {
  background: #404040;
}

.social-ft {
  background: #fde6e5;
  padding: 78px 0 92px;
}

@media screen and (max-width: 834px) {
  .social-ft {
    padding: 38px 0 45px;
  }
}

.social-ft .list-social {
  max-width: 758px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .social-ft .list-social {
    padding: 0 25px;
  }
}

.social-ft .list-social .item {
  width: 32.98%;
}

@media screen and (max-width: 640px) {
  .social-ft .list-social .item {
    width: 100%;
    max-width: 59.35%;
    margin: 0 auto 43px;
  }
}

.social-ft .list-social .item:last-of-type {
  width: calc(100% - 32.98%);
  padding-left: 58px;
}

@media screen and (max-width: 834px) {
  .social-ft .list-social .item:last-of-type {
    padding-left: 25px;
  }
}

@media screen and (max-width: 640px) {
  .social-ft .list-social .item:last-of-type {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.social-ft .list-social .item__ttl {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .social-ft .list-social .item__ttl {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .social-ft .list-social .item__ttl {
    text-align: center;
  }
}

.social-ft .list-social .item__content .img {
  position: relative;
}

.social-ft .list-social .item__content .img iframe {
  width: 100% !important;
}

.social-ft .list-social .item__content .img .note {
  width: 80px;
  height: 40px;
  border: 1px solid #ff0000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #ff0000;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 834px) {
  .social-ft .list-social .item__content .img .note {
    display: none;
  }
}

.social-ft .list-social .item .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -10px;
}

.social-ft .list-social .item .flex .img {
  width: 33%;
  padding: 0 5px;
}

@media screen and (max-width: 640px) {
  .social-ft .list-social .item .flex .img {
    width: 100%;
    padding: 10px 40px;
    max-width: 30em;
    margin: 0 auto;
  }
}

.main-ft {
  padding: 54px 0 20px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .main-ft {
    padding: 75px 0 20px;
  }
}

.main-ft .menu-ft {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 27px;
}

@media screen and (max-width: 834px) {
  .main-ft .menu-ft {
    padding: 0 25px;
    margin-bottom: 18px;
  }
}

.main-ft .menu-ft li {
  padding: 0 9px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .main-ft .menu-ft li {
    padding: 0 6px;
    margin-bottom: 7px;
  }
}

.main-ft .menu-ft li:after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background: #fde6e5;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 834px) {
  .main-ft .menu-ft li:after {
    height: 12px;
    top: 2px;
  }
}

.main-ft .menu-ft li:last-of-type:after {
  display: none;
}

.main-ft .menu-ft li a {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fde6e5;
}

@media screen and (max-width: 834px) {
  .main-ft .menu-ft li a {
    font-size: 1.3rem;
  }
}

.main-ft .list-sns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .main-ft .list-sns {
    margin-bottom: 26px;
  }
}

.main-ft .list-sns li {
  margin-right: 10px;
}

.main-ft .list-sns li:first-of-type {
  margin-right: 20px;
}

@media screen and (max-width: 834px) {
  .main-ft .list-sns li:first-of-type {
    margin-right: 10px;
  }
}

.main-ft .list-sns li:first-of-type a {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.5rem;
  font-weight: bold;
  width: 90px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fde6e5;
  color: #404040;
}

@media screen and (max-width: 834px) {
  .main-ft .list-sns li:first-of-type a {
    width: 70px;
    height: 22px;
    font-size: 1.4rem;
  }
}

.main-ft .ico-ft {
  position: absolute;
  top: -80px;
  right: 0;
  transform: translateX(100%);
}

@media screen and (max-width: 834px) {
  .main-ft .ico-ft {
    top: -112px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.main-ft .inner {
  text-align: center;
}

.main-ft .ft-wp {
  display: inline-block;
  position: relative;
}

.info-ft {
  text-align: center;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
}

.info-ft .tel {
  font-size: 1.6rem;
  color: #fde6e5;
  margin-bottom: 14px;
}

@media screen and (max-width: 834px) {
  .info-ft .tel {
    font-size: 1.2rem;
  }
}

.info-ft .tel a {
  color: #fde6e5;
  font-size: 2.8rem;
  line-height: 1;
  letter-spacing: 1px;
  display: inline-block;
  margin: 0 6px;
}

@media screen and (max-width: 834px) {
  .info-ft .tel a {
    font-size: 2.6rem;
  }
}

.info-ft .address {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fde6e5;
  line-height: 1.75;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

@media screen and (max-width: 834px) {
  .info-ft .address {
    font-size: 1.2rem;
    margin-bottom: 17px;
  }
}

.info-ft .copy-right {
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
  color: #fde6e5;
}

@media screen and (max-width: 834px) {
  .info-ft .copy-right {
    font-size: 1rem;
  }
}

.cdsROW.narrow {
  width: 100% !important;
}

@media screen and (max-width: 834px) {
  .scrolled-down #header .logo {
    opacity: 0 !important;
    visibility: hidden !important;
    z-index: -1;
    transition-delay: 0.3s;
  }
}

.inner {
  display: block;
  max-width: 1130px;
  padding: 0 15px;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}

@media screen and (min-width: 835px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.ttlcomp-01 {
  display: block;
  text-align: center;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  margin-bottom: 22px;
}

.ttlcomp-01 span {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;
  color: white;
  width: 120px;
  height: 120px;
  background: #404040;
  border-radius: 50%;
  font-size: 6.6rem;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .ttlcomp-01 span {
    width: 69px;
    height: 69px;
    font-size: 3.5rem;
  }
}

.ttlcomp-01 img {
  height: auto !important;
}

.ttlcomp-02 {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  font-feature-settings: "palt" 1;
  letter-spacing: 3px;
  margin-bottom: 24px;
}

@media screen and (max-width: 834px) {
  .ttlcomp-02 {
    font-size: 2.1rem;
    margin-bottom: 17px;
  }
}

.ttlcomp-03 {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .ttlcomp-03 {
    margin-bottom: 11px;
  }
}

.ttlcomp-03 span {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 5rem;
  font-weight: 600;
  display: block;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 834px) {
  .ttlcomp-03 span {
    font-size: 2.8rem;
  }
}

.ttlcomp-03 small {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2.5rem;
  font-weight: 600;
  display: block;
  letter-spacing: 1px;
}

@media screen and (max-width: 834px) {
  .ttlcomp-03 small {
    font-size: 1.5rem;
    margin-bottom: 3px;
  }
}

.ttlcomp-04 {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 47px;
}

@media screen and (max-width: 834px) {
  .ttlcomp-04 {
    font-size: 2.25rem;
    margin-bottom: 30px;
  }
}

.txt-basic {
  line-height: 1.69444444;
  font-size: 1.8rem;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .txt-basic {
    font-size: 1.6rem;
  }
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  line-height: 1;
  width: 240px;
  height: 50px;
  font-weight: 600;
  margin: 0 auto;
  background: #404040;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 834px) {
  .btn {
    font-size: 1.3rem;
    width: 180px;
    height: 38px;
  }
}

.btn-white {
  background: white;
  color: #404040;
}

.btn-comp01 {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  max-width: 530px;
  height: 60px;
  width: 100%;
  background: #fff;
  font-size: 2.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404040;
  position: relative;
  font-feature-settings: "palt" 1;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 834px) {
  .btn-comp01 {
    font-size: 1.3rem;
    max-width: 290px;
    height: 38px;
  }
}

.btn-comp01:after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background: url("../img/common/ico-arrow.png") no-repeat center/cover;
  position: absolute;
  top: calc(50% - 12px);
  right: 40px;
}

@media screen and (max-width: 834px) {
  .btn-comp01:after {
    width: 15px;
    height: 15px;
    top: calc(50% - 7.5px);
    right: 10px;
  }
}

.btn-list {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.5rem;
  font-weight: 700;
  max-width: 155px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  height: 34px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404040;
}

@media screen and (max-width: 834px) {
  .btn-list {
    max-width: 165px;
    height: 38px;
    font-size: 1.3rem;
  }
}

.btn-list span {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-list span:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url("../img/common/ico-arrow-left.png") no-repeat center/cover;
  position: relative;
  margin-right: 10px;
}

.btn-submit {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.7rem;
  font-weight: 700;
  max-width: 175px;
  width: 100%;
  margin: 0 auto;
  height: 45px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404040;
  border: none;
  outline: 0;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  transition: 0.3s ease all;
}

.btn-submit:hover {
  opacity: 0.7;
  transition: 0.3s ease all;
}

@media screen and (max-width: 834px) {
  .btn-submit {
    max-width: 165px;
    height: 38px;
    font-size: 1.3rem;
  }
}

.btn-submit span {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-submit span:after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url("../img/common/ico-arrow.png") no-repeat center/cover;
  position: relative;
  margin-left: 10px;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  background: white;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 1.5rem;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.bg-white {
  background: white !important;
}

.bg-orange {
  background: #e66031 !important;
}

.bg-light-blue {
  background: #b9d1f1 !important;
}

.bg-yellow {
  background: #eced4f !important;
}

.bg-violet {
  background: #cba4d2 !important;
}

.bg-pink {
  background: #fde6e5 !important;
}

.sec-banner {
  padding: 67px 0 75px;
  background: url("../img/common/bg-banner.jpg") no-repeat center/cover;
}

@media screen and (max-width: 834px) {
  .sec-banner {
    padding: 37px 0;
  }
}

.sec-banner .banner-ttl {
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  .sec-banner .banner-ttl {
    margin-bottom: 20px;
  }
  .sec-banner .banner-ttl img {
    width: 223.5px;
  }
}

.sec-banner .banner-ttl span {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3.2rem;
  font-weight: 600;
  color: #fff;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 6px;
}

@media screen and (max-width: 834px) {
  .sec-banner .banner-ttl span {
    font-size: 1.8rem;
  }
}

.sec-banner .banner-ttl small {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  color: #fff;
  font-size: 1.8rem;
  display: block;
}

@media screen and (max-width: 834px) {
  .sec-banner .banner-ttl small {
    font-size: 1.25rem;
  }
}

.sec-banner .btn-group li {
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .sec-banner .btn-group li {
    margin-bottom: 8px;
  }
}

.sec-banner .btn-group li:last-of-type {
  margin-bottom: 0;
}

.sec-banner .btn-group li:first-of-type .btn span {
  position: relative;
  left: -18px;
}

@media screen and (max-width: 834px) {
  .sec-banner .btn-group li:first-of-type .btn span {
    left: -12px;
  }
}

.sec-banner .ico-banner {
  bottom: -110px;
  left: -188px;
  position: absolute;
}

@media screen and (max-width: 834px) {
  .sec-banner .ico-banner {
    left: auto;
    bottom: auto;
    top: 0;
    right: 0;
    transform: translate(16%, -70%);
  }
}

.sec-banner .banner-wp {
  position: relative;
  max-width: 530px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .sec-banner .banner-wp {
    max-width: 290px;
  }
}

.fade-up {
  opacity: 0;
  transform: translate(0px, 120px);
  transition: transform 2s, opacity 1.5s;
}

.fade-up.active {
  transform: translate(0px, 0px);
  opacity: 1;
}

.mv-list .item {
  transition: opacity 4000ms linear 0s !important;
}

.mv-list .item.slick-active img {
  opacity: 1;
}

.mv-list img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  font-family: 'object-fit: cover';
  opacity: 0;
}

@media screen and (max-width: 834px) {
  .mv-list img {
    height: 68vh;
  }
}

.about-block {
  display: block;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 834px) {
  .about-block {
    padding: 125px 0 0;
  }
}

.about-block .item {
  position: absolute;
}

.about-block .item-01 {
  top: -148px;
  left: -33px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 834px) {
  .about-block .item-01 {
    display: none;
  }
}

.about-block .item-01.active {
  opacity: 1;
  visibility: visible;
}

.about-block .item-02 {
  top: 200px;
  left: -107px;
}

@media screen and (max-width: 834px) {
  .about-block .item-02 {
    display: none;
  }
}

.about-block .item-03 {
  top: -195px;
  right: -150px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 834px) {
  .about-block .item-03 {
    display: none;
  }
}

.about-block .item-03.active {
  opacity: 1;
  visibility: visible;
}

.about-block .item-04 {
  display: block;
  max-width: 295px;
  margin: 0 auto;
  top: -40px;
  left: 0;
  right: 0;
}

.about-block__txt {
  padding: 0 0 0 118px;
  text-align: justify;
  margin-bottom: 38px;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 834px) {
  .about-block__txt {
    padding: 0 26px;
    font-size: 1.2rem;
    line-height: 1.6666666666;
  }
}

.about-block__txt .ttl {
  display: block;
  font-size: 3.2rem;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: 600;
  margin-bottom: 22px;
}

@media screen and (max-width: 834px) {
  .about-block__txt .ttl {
    text-align: center;
    font-size: 2.3rem;
    line-height: 1.3;
    margin-bottom: 12px;
  }
}

.about-block__txt a {
  color: #404040;
  text-decoration: underline;
  font-weight: bold;
}

.about-block__txt p {
  margin-bottom: 33px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 834px) {
  .about-block__txt p {
    margin-bottom: 20px;
  }
}

.about-block__txt p:last-child {
  margin-bottom: 0;
}

.about-block__note {
  background: white;
  display: block;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 20px;
}

@media screen and (max-width: 834px) {
  .about-block__note {
    font-size: 1.2rem;
    padding: 14px 20px;
  }
}

.about-block__note .ttl {
  margin: 0 0 10px 20px;
  display: inline-block;
  text-align: center;
  width: 110px;
  font-size: 1.8rem;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  background: #404040;
  color: white;
  font-weight: 600;
}

@media screen and (max-width: 834px) {
  .about-block__note .ttl {
    font-size: 1.4rem;
    margin: 0 auto 16px;
    padding: 6px;
    min-width: 85px;
    width: auto;
  }
}

.about-block__note p {
  width: calc(100% - 110px);
  padding: 0 0 0 20px;
  letter-spacing: 0.5px;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 834px) {
  .about-block__note p {
    width: 100%;
    padding: 0;
    line-height: 1.45833333;
  }
}

.about-block__note p span, .about-block__note p strong {
  display: block;
  font-size: 1.3rem;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 3px;
}

.about-block .detail-btn {
  margin-top: 10px;
  max-width: 200px;
  height: 40px;
  font-size: 2rem;
}

.about-block .detail-btn .detail {
  margin-right: 40px;
}

@media screen and (max-width: 834px) {
  .about-block .detail-btn .detail {
    margin-right: 0;
  }
}

.concept-item .item {
  position: relative;
}

.concept-item .item a {
  display: block;
}

.concept-item .item a:hover {
  opacity: 1;
}

.concept-item__banner {
  position: relative;
}

@media screen and (max-width: 834px) {
  .concept-item__banner {
    padding: 0 0 100px;
  }
}

.concept-item__banner img {
  width: 100%;
}

.concept-item__txt {
  text-align: center;
  font-weight: 500;
  color: #231815;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 66px 0 60px;
}

@media screen and (max-width: 834px) {
  .concept-item__txt {
    color: black;
    font-size: 1.3rem;
    line-height: 1.9230;
    padding: 33px 0 31px;
  }
}

.concept-item__txt:before {
  content: "";
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  right: 0;
  height: 20vw;
}

.concept-item__txt#item-01:before {
  background: url("../img/index/bf-orange.png") no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-01:before {
    background: url("../img/index/bf-orange-sp.png") no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }
}

.concept-item__txt#item-01 .ttlcomp-01 .note {
  right: 0;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-01 .ttlcomp-01 .note {
    right: 15%;
    width: 75px;
  }
}

.concept-item__txt#item-02:before {
  background: url("../img/index/bf-light-blue.png") no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-02:before {
    background: url("../img/index/bf-light-blue-sp.png") no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }
}

.concept-item__txt#item-02 .ttlcomp-01 .note {
  bottom: 10px;
  left: -23px;
  width: 167px;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-02 .ttlcomp-01 .note {
    width: 94px;
    left: 10%;
    bottom: 0;
  }
}

.concept-item__txt#item-03:before {
  background: url("../img/index/bf-yellow.png") no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-03:before {
    background: url("../img/index/bf-yellow-sp.png") no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }
}

.concept-item__txt#item-03 .ttlcomp-01 .note {
  bottom: 0;
  right: -29px;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-03 .ttlcomp-01 .note {
    right: 11%;
    width: 100px;
  }
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-04 {
    padding: 46px 0 46px;
  }
}

.concept-item__txt#item-04:before {
  background: url("../img/index/bf-pink.png") no-repeat;
  background-size: 100%;
  background-position: bottom center;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-04:before {
    background: url("../img/index/bf-pink-sp.png") no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }
}

.concept-item__txt#item-04 .ttlcomp-01 .note {
  left: 32px;
}

@media screen and (max-width: 834px) {
  .concept-item__txt#item-04 .ttlcomp-01 .note {
    left: 21%;
    width: 58px;
    bottom: -13px;
  }
}

.concept-item__txt .ttlcomp-01 {
  width: 520px;
  position: absolute;
  top: -81px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .concept-item__txt .ttlcomp-01 {
    width: 100%;
    max-width: 375px;
    top: -50px;
  }
}

.concept-item__txt .ttlcomp-01 .note {
  background: none;
  width: auto;
  height: auto;
  position: absolute;
  bottom: 0;
}

.sec-mv {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 834px) {
  .sec-mv {
    height: 68vh;
  }
}

.sec-mv:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -70px;
  background: url("../img/index/bg-mv.png") no-repeat;
  background-size: cover;
  background-position: bottom center;
}

@media screen and (min-width: 835px) {
  .sec-mv:after {
    opacity: 0;
    visibility: hidden;
    transition: ease 3.5s;
    transition-delay: 1.5s;
  }
}

@media screen and (max-width: 834px) {
  .sec-mv:after {
    bottom: -15px;
    background: url("../img/index/bg-mv-sp.png") no-repeat;
    background-size: cover;
    background-position: bottom center;
    left: -3px;
    right: -3px;
  }
}

.sec-about {
  background: #fde6e5;
  padding: 95px 0 47px;
}

@media screen and (max-width: 834px) {
  .sec-about {
    padding: 0 0 51px;
  }
}

.list-post {
  margin-left: 20px;
}

@media screen and (max-width: 834px) {
  .list-post {
    margin-left: 0;
  }
}

.list-post li {
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .list-post li {
    margin-bottom: 7px;
  }
}

.list-post li a {
  display: flex;
}

.list-post li a span.date {
  width: 130px;
}

@media screen and (max-width: 834px) {
  .list-post li a span.date {
    width: 80px;
  }
}

.list-post li a span.txt {
  width: calc(100% - 130px);
  padding-top: 5px;
  display: block;
  font-size: 1.3rem;
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .list-post li a span.txt {
    width: calc(100% - 80px);
    padding-top: 0;
  }
}

.link-detail {
  margin-left: 20px;
  text-decoration: underline;
  font-size: 1.3rem;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .link-detail {
    margin-left: 0;
  }
}

.sec-mv-page .mv-heading {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading {
    top: 16.5%;
  }
}

.sec-mv-page .mv-heading .ttlcomp-01 {
  margin-bottom: 26px;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading .ttlcomp-01 {
    margin-bottom: 13px;
  }
}

.sec-mv-page .mv-heading .ttlcomp-01 span {
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading .ttlcomp-01 span {
    width: 69px;
    height: 69px;
    font-size: 3.5rem;
  }
}

.sec-mv-page .mv-heading p {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 2px;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading p {
    font-size: 2.25rem;
    letter-spacing: 1px;
  }
}

.sec-mv-page .mv-heading .ico {
  position: absolute;
  top: 58px;
  left: -66%;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading .ico {
    top: -25px;
    left: 50%;
    transform: translateX(43%);
  }
}

.sec-mv-page .mv-heading .ico img {
  height: auto;
}

@media screen and (min-width: 835px) {
  .sec-mv-page .mv-heading .ico img {
    width: 196px;
  }
}

.sec-mv-page .mv-heading .ico-kyo {
  top: 87px;
  left: auto;
  right: -113%;
  width: 130px;
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading .ico-kyo {
    top: -28px;
    left: -78px;
    right: auto;
    transform: translateX(0);
  }
}

@media screen and (max-width: 834px) {
  .sec-mv-page .mv-heading .ico-den {
    top: -34px;
    transform: translateX(88%);
  }
}

.sec-mv-page .mv-heading .heading-inner {
  display: inline-block;
  position: relative;
}

.sec-mv-page .img img {
  width: 100%;
  height: 800px;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

@media screen and (max-width: 834px) {
  .sec-mv-page .img img {
    height: auto;
  }
}

.sec-hot-spring {
  background: #b9d1f1;
}

.sec-hot-spring .list-item .item {
  padding-top: 70px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item {
    padding-top: 32px;
  }
}

.sec-hot-spring .list-item .item .ico {
  position: absolute;
  top: 42px;
  right: 15.5%;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item .ico {
    top: 176px;
    right: 38px;
  }
}

.sec-hot-spring .list-item .item .ico-den {
  top: auto;
  right: auto;
  bottom: -108px;
  left: 16.2%;
  z-index: 3;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item .ico-den {
    top: 13px;
    right: 24px;
    bottom: auto;
    left: auto;
  }
}

.sec-hot-spring .list-item .item__heading {
  position: relative;
  z-index: 2;
  max-width: 730px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto -35px;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__heading {
    margin-bottom: 8px;
  }
}

.sec-hot-spring .list-item .item__img {
  position: relative;
  z-index: 1;
}

.sec-hot-spring .list-item .item__img:before {
  content: "";
  display: block;
  width: 100%;
  height: 7.4vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url("../img/yu/before.png") no-repeat center/cover;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__img:before {
    height: 54px;
  }
}

.sec-hot-spring .list-item .item__img-other img {
  width: 100%;
  max-height: 720px;
  object-fit: cover;
  font-family: 'object-fit: cover';
  object-position: bottom;
}

.sec-hot-spring .list-item .item__img-other-02 img {
  max-height: 700px;
}

.sec-hot-spring .list-item .item__info {
  background: #fff;
  padding-top: 65px;
  padding-bottom: 62px;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__info {
    padding-top: 27px;
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__info .ttlcomp-02 {
    margin-bottom: 33px;
  }
}

.sec-hot-spring .list-item .item__info .txt-basic {
  line-height: 1.6;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__info .txt-basic {
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.sec-hot-spring .list-item .item__info .txt-basic p {
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item__info .txt-basic p {
    margin-bottom: 20px;
  }
}

.sec-hot-spring .list-item .item__info .txt-basic p:last-of-type {
  margin-bottom: 0;
}

.sec-hot-spring .list-item .item:nth-child(even) .ico {
  left: 11.8%;
  right: auto;
  top: -68px;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .list-item .item:nth-child(even) .ico {
    left: 44px;
    right: auto;
    top: 186px;
  }
}

.sec-hot-spring .wp-inner {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .sec-hot-spring .wp-inner {
    padding: 0 42px;
  }
}

.z2 {
  z-index: 2 !important;
  position: relative;
  margin-bottom: -6px !important;
}

.sec-room {
  background: #eced4f;
  padding: 70px 0 35px;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .sec-room {
    padding: 36px 0 20px;
  }
}

.sec-room .heading-block {
  margin-bottom: 36px;
}

@media screen and (max-width: 834px) {
  .sec-room .heading-block {
    margin-bottom: 24px;
  }
}

.sec-room .heading-block .ttlcomp-02 {
  margin-bottom: 5px;
}

@media screen and (max-width: 834px) {
  .sec-room .heading-block .ttlcomp-02 {
    margin-bottom: 0;
  }
}

.sec-room .heading-block .txt-basic {
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .sec-room .heading-block .txt-basic {
    font-size: 1.3rem;
  }
}

.sec-room:after {
  content: "";
  display: block;
  width: 100%;
  height: 8vw;
  background: url(../img/kyo/after-yellow.png) no-repeat center/cover;
  position: absolute;
  top: 100%;
  left: 0;
}

@media screen and (max-width: 834px) {
  .sec-room:after {
    display: none;
  }
}

.room-block .item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

@media screen and (max-width: 640px) {
  .room-block .item {
    padding: 0 3px 0 12px;
    margin-bottom: 47px;
  }
}

.room-block .item__info {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .room-block .item__info {
    padding-top: 0;
    padding-left: 16px;
    padding-right: 18px;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item__info {
    width: 100%;
    order: 2;
  }
}

.room-block .item__info .txt-basic {
  line-height: 1.6;
  margin-bottom: 21px;
}

@media screen and (max-width: 834px) {
  .room-block .item__info .txt-basic {
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.room-block .item__info .img-sub {
  margin: 0 -18px 27px -18px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .room-block .item__info .img-sub {
    margin: 0 auto 20px;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item__info .img-sub {
    order: 2;
    margin: 0 auto;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item__info .flex-room {
    display: flex;
    flex-wrap: wrap;
  }
}

.room-block .item__img {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .room-block .item__img {
    order: 1;
    width: 100%;
    margin-bottom: -23px;
  }
}

.room-block .item:nth-child(even) .item__info {
  order: 2;
}

.room-block .item:nth-child(even) .item__img {
  order: 1;
}

.room-block .item-other .item__info {
  width: calc(100% - 54.8%);
  padding-right: 0;
}

@media screen and (max-width: 834px) {
  .room-block .item-other .item__info {
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item-other .item__info {
    width: 100%;
  }
}

.room-block .item-other .item__img {
  width: 54.8%;
}

@media screen and (max-width: 834px) {
  .room-block .item-other .item__img {
    width: 50%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item-other .item__img {
    width: 100%;
  }
}

.room-block .item:nth-child(n + 4) {
  margin-left: -13px;
  margin-bottom: 52px;
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) {
    margin-left: 0;
    align-items: center;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item:nth-child(n + 4) {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.room-block .item:nth-child(n + 4) .item__img {
  width: 40%;
  order: 1;
  position: relative;
}

@media screen and (max-width: 640px) {
  .room-block .item:nth-child(n + 4) .item__img {
    width: 100%;
    padding-right: 80px;
    padding-left: 15px;
    margin-bottom: 17px;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item:nth-child(n + 4) .item__img .ttlcomp-03 {
    text-align: left;
    position: absolute;
    top: 28px;
    right: 26px;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__img .ttlcomp-03 span {
    font-size: 2rem;
  }
}

.room-block .item:nth-child(n + 4) .item__info {
  order: 2;
  width: calc(100% - 40%);
  padding-left: 35px;
}

@media screen and (max-width: 1024px) {
  .room-block .item:nth-child(n + 4) .item__info {
    padding-left: 15px;
  }
}

@media screen and (max-width: 640px) {
  .room-block .item:nth-child(n + 4) .item__info {
    width: 100%;
    padding-left: 0;
  }
}

.room-block .item:nth-child(n + 4) .item__info .txt-basic {
  margin-bottom: 0;
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__info .txt-basic {
    padding-left: 15px;
    margin-bottom: 18px;
  }
}

.room-block .item:nth-child(n + 4) .item__info .img-sub {
  margin-bottom: 20px;
}

.room-block .item:nth-child(n + 4) .item__info .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.room-block .item:nth-child(n + 4) .item__info .flex .col-left {
  width: 54%;
  padding-right: 30px;
}

@media screen and (max-width: 1024px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-left {
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-left {
    width: 100%;
    padding-right: 0;
  }
}

.room-block .item:nth-child(n + 4) .item__info .flex .col-right {
  width: 46%;
}

@media screen and (max-width: 1024px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right {
    padding-left: 15px;
    padding-right: 7px;
  }
}

@media screen and (max-width: 1024px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right .img-sub {
    width: 49%;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right .img-sub {
    width: 50%;
    padding-left: 7px;
    margin-top: -4px;
  }
}

@media screen and (max-width: 1024px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right .room-info {
    width: 49%;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:nth-child(n + 4) .item__info .flex .col-right .room-info {
    width: 50%;
    padding: 10px 12px 6px;
    line-height: 1.7;
  }
}

.room-block .item:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .room-block .item:last-of-type .item__img {
    padding-right: 24px;
    padding-left: 90px;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:last-of-type .item__img .ttlcomp-03 {
    position: absolute;
    left: 16px;
    top: 34px;
    right: auto;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:last-of-type .item__info .flex .col-right .img-sub {
    order: 1;
    padding-left: 0;
  }
}

@media screen and (max-width: 834px) {
  .room-block .item:last-of-type .item__info .flex .col-right .room-info {
    order: 2;
  }
}

.room-block .room-info {
  background: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.56;
  padding: 10px 20px;
}

@media screen and (max-width: 834px) {
  .room-block .room-info {
    order: 1;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

.bg-violet {
  background: #cba4d2;
}

.bg-violet .list-item .item__img:before {
  background: url("../img/den/before.png") no-repeat center/cover;
}

.bg-violet .list-item .item__info {
  background: #cba4d2;
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item {
    padding-top: 36px;
  }
}

.page-den_index .sec-hot-spring .list-item .item__heading {
  margin-bottom: -10px;
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item__heading .ttlcomp-02 {
    margin-bottom: 30px;
  }
}

.page-den_index .sec-hot-spring .list-item .item__heading .txt-basic {
  font-size: 1.6rem;
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item__heading .txt-basic {
    text-align: center;
  }
}

.page-den_index .sec-hot-spring .list-item .item__info {
  padding-top: 73px;
  padding-bottom: 0;
  margin-bottom: -35px;
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item__info {
    padding-top: 37px;
    margin-bottom: -10px;
  }
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item__info .wp-inner {
    padding: 0 15px;
  }
}

.page-den_index .sec-hot-spring .list-item .item__info .txt-basic {
  font-size: 1.6rem;
  line-height: 1.75;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-hot-spring .list-item .item__info .txt-basic {
    text-align: center;
  }
}

.page-den_index .sec-hot-spring .list-item .item__info .txt-basic p {
  margin-bottom: 5px;
}

.page-den_index .sec-hot-spring .list-item .item__info .txt-basic a {
  text-decoration: underline;
  color: #404040;
}

@media screen and (min-width: 835px) {
  .page-den_index .sec-mv-page .mv-heading .ico {
    width: 183px;
    left: auto;
    right: -232px;
    top: 0;
  }
}

@media screen and (max-width: 834px) {
  .page-den_index .sec-mv-page .mv-heading .ico {
    top: -33px;
    left: auto;
    right: 13px;
    width: 79px;
  }
}

.page-den_index .list-item {
  margin-bottom: 0;
}

.sec-five-senses {
  padding-top: 75px;
  margin-bottom: 101px;
}

@media screen and (max-width: 834px) {
  .sec-five-senses {
    padding-top: 40px;
  }
}

@media screen and (max-width: 640px) {
  .sec-five-senses {
    padding-bottom: 35px;
    margin-bottom: 0;
  }
}

.sec-five-senses:after {
  content: "";
  display: block;
  width: 100%;
  height: 7.4vw;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -7.4vw;
  background: url("../img/den/before.png") no-repeat center/cover;
}

.sec-five-senses .heading {
  text-align: center;
  margin-bottom: 18px;
}

.sec-five-senses .heading .ttlcomp-02 {
  display: inline-block;
}

@media screen and (max-width: 834px) {
  .sec-five-senses .heading .ttlcomp-02 {
    font-size: 2.5rem;
    margin-bottom: 6px;
  }
}

.sec-five-senses .heading .ico {
  position: absolute;
  top: 90px;
  right: auto;
  margin-left: 14px;
}

@media screen and (max-width: 834px) {
  .sec-five-senses .heading .ico {
    top: -30px;
    right: auto;
    margin-left: -14px;
  }
}

.list-senses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -12.5px -20px -12.5px;
}

@media screen and (max-width: 834px) {
  .list-senses {
    margin: 0;
  }
}

.list-senses .item {
  width: 33.33%;
  padding: 0 12.5px;
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .list-senses .item {
    width: 100%;
    padding: 0;
    margin-bottom: 38px;
  }
}

@media screen and (max-width: 834px) {
  .list-senses .item:last-of-type {
    margin-bottom: 0;
  }
}

.list-senses .item__ttl {
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 834px) {
  .list-senses .item__ttl {
    margin-bottom: 12px;
  }
}

.list-senses .item__ttl span {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 5rem;
  font-weight: 600;
  display: block;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .list-senses .item__ttl span {
    font-size: 2.5rem;
  }
}

.list-senses .item__ttl small {
  font-size: 1.8rem;
  font-weight: 500;
  display: block;
}

@media screen and (max-width: 834px) {
  .list-senses .item__ttl small {
    font-size: 1.6rem;
  }
}

.list-senses .item__img {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 834px) {
  .list-senses .item__img {
    max-width: 162px;
  }
}

.list-senses .item__img img {
  display: block;
  margin: 0 auto;
}

.sec-den-bottom {
  padding-top: 90px;
  padding-bottom: 108px;
}

@media screen and (max-width: 834px) {
  .sec-den-bottom {
    padding-top: 48px;
  }
}

@media screen and (max-width: 640px) {
  .sec-den-bottom {
    padding-bottom: 23px;
  }
}

@media screen and (max-width: 834px) {
  .sec-den-bottom .ttlcomp-02 {
    margin-bottom: 30px;
  }
}

.sec-den-bottom .txt-des {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 95px;
}

@media screen and (max-width: 834px) {
  .sec-den-bottom .txt-des {
    line-height: 1.7;
    letter-spacing: -1px;
    margin-bottom: 40px;
  }
}

.sec-den-bottom .list-den {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

@media screen and (max-width: 834px) {
  .sec-den-bottom .list-den {
    margin: 0 -10px;
  }
}

.sec-den-bottom .list-den .item {
  width: 33.33%;
  padding: 0 12px;
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .sec-den-bottom .list-den .item {
    width: 50%;
    padding: 0 10px;
  }
}

.sec-den-bottom .list-den .item .img {
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (min-width: 835px) {
  .page-kyo_index + footer .sec-banner {
    padding: 195px 0 75px;
  }
}

.sec-mv-other {
  margin-bottom: -90px;
}

@media screen and (max-width: 834px) {
  .sec-mv-other {
    margin-bottom: -47px;
  }
}

@media screen and (max-width: 834px) {
  .sec-access {
    padding-bottom: 26px;
  }
}

.sec-access .bg-pink {
  position: relative;
  margin-bottom: 156px;
}

@media screen and (max-width: 834px) {
  .sec-access .bg-pink {
    padding-bottom: 12px;
    margin-bottom: 68px;
  }
}

.sec-access .bg-pink:after {
  content: "";
  display: block;
  width: 100%;
  height: 7.4vw;
  position: absolute;
  bottom: -7.4vw;
  left: 0;
  background: url("../img/access/before-access.png") no-repeat center/cover;
}

@media screen and (max-width: 834px) {
  .sec-access .bg-pink:after {
    height: 30px;
    bottom: -30px;
    background: url("../img/access/before-access-sp.png") no-repeat center/cover;
  }
}

.access-ttl-sub {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .access-ttl-sub {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 29px;
  }
}

.access-block {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .access-block {
    padding: 0 30px;
  }
}

.list-item {
  margin-bottom: 55px;
}

@media screen and (max-width: 834px) {
  .list-item {
    margin-bottom: 20px;
  }
}

.list-item dl {
  margin-bottom: 50px;
}

@media screen and (max-width: 834px) {
  .list-item dl {
    margin-bottom: 34px;
  }
}

.list-item dl:last-of-type {
  margin-bottom: 0;
}

.list-item dl dt {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

@media screen and (max-width: 834px) {
  .list-item dl dt {
    font-size: 1.5rem;
  }
}

.list-item dl dd {
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 500;
  display: inline-block;
  border-top: 1px solid #404040;
  padding-top: 5px;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 834px) {
  .list-item dl dd {
    display: block;
    font-size: 1.7rem;
    padding-top: 0;
  }
}

.list-item dl dd span {
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
}

@media screen and (max-width: 834px) {
  .list-item dl dd span {
    font-size: 1.5rem;
    margin-right: 25px;
    line-height: 1.75;
  }
}

.list-item .flex {
  display: flex;
  flex-wrap: wrap;
}

.list-item .flex dl {
  margin-right: 64px;
}

@media screen and (max-width: 834px) {
  .list-item .flex dl {
    margin-right: 50px;
  }
}

.list-item .flex dl:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 834px) {
  .list-item--style02 {
    padding: 0 15px;
  }
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl {
    margin-bottom: 25px;
  }
}

.list-item--style02 dl dd {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl dd {
    padding-top: 5px;
  }
}

.list-item--style02 dl dd ul {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl dd ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.list-item--style02 dl dd ul:last-of-type {
  padding-left: 35px;
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl dd ul:last-of-type {
    padding-left: 0;
  }
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl dd ul li {
    margin-right: 30px;
  }
}

.list-item--style02 dl dd ul li:last-of-type {
  margin-right: 0;
}

.list-item--style02 dl dd p {
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .list-item--style02 dl dd p {
    margin-bottom: 26px;
  }
}

.list-item--style02 dl dd p:last-of-type {
  margin-bottom: 0;
}

.list-item--style02 dl dd a {
  color: #404040;
}

.map-block {
  margin-bottom: 56px;
}

@media screen and (max-width: 834px) {
  .map-block {
    padding: 0 30px;
    margin-bottom: 20px;
  }
}

.map-block iframe {
  width: 100%;
  height: 580px;
}

@media screen and (max-width: 834px) {
  .map-block iframe {
    width: 100%;
    height: 153px;
  }
}

.btn-group .btn-comp01 {
  margin: 0 auto;
}

.sec-policy {
  background: #fde6e5;
  padding-bottom: 105px;
}

@media screen and (max-width: 834px) {
  .sec-policy {
    padding-bottom: 43px;
  }
}

.sec-policy .ttlcomp-04 {
  margin-bottom: 8px;
}

.sec-policy .txt-intro {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 46px;
}

@media screen and (max-width: 834px) {
  .sec-policy .txt-intro {
    margin-bottom: 34px;
    font-size: 1.5rem;
    line-height: 1.75;
  }
}

.policy-content {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
}

@media screen and (max-width: 834px) {
  .policy-content {
    padding: 0 25px;
  }
}

.policy-content .item {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 27px;
}

@media screen and (max-width: 834px) {
  .policy-content .item {
    line-height: 2;
  }
}

.policy-content .item:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 834px) {
  .policy-content .item:last-of-type {
    line-height: 1.8;
  }
}

.policy-content .item strong {
  font-size: 1.8rem;
  font-weight: 600;
  display: block;
}

@media screen and (max-width: 834px) {
  .policy-content .item strong {
    font-size: 1.7rem;
  }
}

.policy-content .item .tel {
  font-size: 3rem;
  font-weight: 500;
  color: #404040;
}

@media screen and (max-width: 834px) {
  .policy-content .item .tel {
    font-size: 2.5rem;
  }
}

.policy-content .item span {
  font-size: 2rem;
  line-height: 1.5;
}

@media screen and (max-width: 834px) {
  .policy-content .item span {
    font-size: 1.7rem;
  }
}

.policy-content .item span a {
  font-weight: 600;
  color: #404040;
  text-decoration: underline;
}

.sec-news {
  padding-bottom: 98px;
}

@media screen and (max-width: 834px) {
  .sec-news {
    padding-bottom: 76px;
  }
}

.news-block {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .news-block {
    padding: 0 28px;
  }
}

.news-block .item {
  margin-bottom: 77px;
}

@media screen and (max-width: 834px) {
  .news-block .item {
    margin-bottom: 72px;
  }
}

.news-block .item:last-of-type {
  margin-bottom: 0;
}

.news-block .item__ttl {
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid #404040;
  padding-bottom: 4px;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .news-block .item__ttl {
    font-size: 1.7rem;
    margin-bottom: 7px;
  }
}

.news-block .item__info .date {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 6px;
}

@media screen and (max-width: 834px) {
  .news-block .item__info .date {
    margin-bottom: 1px;
  }
}

.news-block .item__content {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 4px;
}

@media screen and (max-width: 834px) {
  .news-block .item__content {
    height: 100px;
    overflow-y: hidden;
  }
}

.news-block .item .btn-group .btn-comp01 {
  max-width: 155px;
  height: 34px;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: auto;
  margin-right: 0;
}

@media screen and (max-width: 834px) {
  .news-block .item .btn-group .btn-comp01 {
    max-width: 165px;
    height: 37px;
    font-size: 1.3rem;
  }
}

.news-block .item .btn-group .btn-comp01:after {
  width: 15px;
  height: 15px;
  top: calc(50% - 7.5px);
  right: 16px;
}

@media screen and (max-width: 834px) {
  .news-block .item .btn-group .btn-comp01:after {
    right: 24px;
  }
}

.news-block .item .btn-group .btn-comp01 span {
  position: relative;
  left: -12px;
}

.post-detail-block {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .post-detail-block {
    padding: 0 28px;
  }
}

.post-detail-block .post-ttl {
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 1px solid #404040;
  padding-bottom: 4px;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .post-detail-block .post-ttl {
    font-size: 1.7rem;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

.post-detail-block .date {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 6px;
}

@media screen and (max-width: 834px) {
  .post-detail-block .date {
    margin-bottom: 0;
  }
}

.post-detail-block .post-detail {
  font-size: 1.5rem;
  line-height: 1.67;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .post-detail-block .post-detail {
    margin-bottom: 20px;
  }
}

.sec-contact {
  padding-bottom: 97px;
}

@media screen and (max-width: 834px) {
  .sec-contact {
    padding-bottom: 50px;
  }
}

.main-form {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .main-form {
    max-width: 290px;
  }
}

.main-form .txt-intro {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 40px;
}

@media screen and (max-width: 834px) {
  .main-form .txt-intro {
    font-size: 1.7rem;
    margin-bottom: 44px;
  }
}

.main-form form dl {
  margin-bottom: 47px;
}

@media screen and (max-width: 834px) {
  .main-form form dl {
    margin-bottom: 23px;
  }
}

.main-form form dl:last-of-type {
  margin-bottom: 98px;
}

@media screen and (max-width: 834px) {
  .main-form form dl:last-of-type {
    margin-bottom: 44px;
  }
}

.main-form form dl dt {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3px;
}

@media screen and (max-width: 834px) {
  .main-form form dl dt {
    font-size: 1.7rem;
    margin-bottom: 5px;
  }
}

.main-form form dl dd .form-input {
  width: 100%;
  height: 40px;
  background: #fff;
  outline: 0;
  border: none;
  appearance: none;
  padding: 0 15px;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .main-form form dl dd .form-input {
    height: 25px;
    font-size: 1.7rem;
  }
}

.main-form form dl dd .form-textarea {
  width: 100%;
  height: 295px;
  background: #fff;
  outline: 0;
  border: none;
  appearance: none;
  padding: 15px;
  font-size: 2rem;
}

@media screen and (max-width: 834px) {
  .main-form form dl dd .form-textarea {
    height: 225px;
    font-size: 1.7rem;
  }
}

.main-form .btn-group {
  display: flex;
  justify-content: center;
  max-width: 360px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .main-form .btn-group .btn-submit {
    margin: 0 10px;
  }
}

.form-confirm dl dd {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2rem;
  font-weight: 600;
}

@media screen and (max-width: 834px) {
  .form-confirm dl dd {
    font-size: 1.7rem;
  }
}

.form-thanks .ttlcomp-04 {
  margin-bottom: 110px;
}

@media screen and (max-width: 834px) {
  .form-thanks .ttlcomp-04 {
    margin-bottom: 75px;
  }
}

.form-thanks .thanks-content {
  font-feature-settings: "palt" 1;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .form-thanks .thanks-content {
    font-size: 1.7rem;
  }
}

.page-syoku_index .mv-heading .ico {
  left: auto;
  right: -61%;
  top: 100px;
}

@media screen and (max-width: 834px) {
  .page-syoku_index .mv-heading .ico {
    right: -8px;
    left: 11px;
    top: -30px;
    transform: translateX(0);
  }
}

.food-block {
  background: #e66031;
  position: relative;
  padding: 70px 0 33px;
  z-index: 2;
}

@media screen and (max-width: 834px) {
  .food-block {
    padding: 37px 0 0;
  }
}

.food-block:after {
  content: "";
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  height: 7.5vw;
  background: url(../img/syoku/af.png) no-repeat;
  background-size: 100%;
}

@media screen and (max-width: 834px) {
  .food-block:after {
    height: 11vw;
    background-size: cover;
    background-position: bottom center;
  }
}

.food-block__ttl {
  display: block;
  text-align: center;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: white;
  margin-bottom: 47px;
}

@media screen and (max-width: 834px) {
  .food-block__ttl {
    font-size: 2.2rem;
    margin-bottom: 24px;
  }
}

.food-block__ttl span {
  position: relative;
}

.food-block__ttl span .ico {
  position: absolute;
  top: 0;
}

.food-block__ttl span .ico-left {
  left: -195px;
  top: -20px;
}

@media screen and (max-width: 834px) {
  .food-block__ttl span .ico-left {
    left: -70px;
    top: 6px;
    width: 92px;
  }
}

.food-block__ttl span .ico-right {
  right: -169px;
  top: 25px;
}

@media screen and (max-width: 834px) {
  .food-block__ttl span .ico-right {
    right: -68px;
    top: -71px;
    width: 73px;
  }
}

.food-block__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.food-block__list .item {
  width: 33.333%;
  padding: 0 15px;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.55555;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .food-block__list .item {
    font-size: 1.6rem;
    line-height: 1.71875;
    width: 100%;
    margin-bottom: 31px;
  }
  .food-block__list .item:last-child {
    margin-bottom: 0;
  }
}

.food-block__list .txt {
  margin-bottom: 16px;
}

@media screen and (max-width: 834px) {
  .food-block__list .txt {
    margin-bottom: 20px;
  }
}

.food-block__list .img img {
  width: 100%;
  border-radius: 50%;
}

@media screen and (max-width: 834px) {
  .food-block__list .img img {
    width: 175px;
  }
}

.food-block__list .status {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 1px solid white;
  border-radius: 50%;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 2.5rem;
  color: white;
  line-height: 1;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 auto;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  margin-bottom: 20px;
}

@media screen and (max-width: 834px) {
  .food-block__list .status {
    width: 70px;
    height: 70px;
    font-size: 1.6rem;
    margin-bottom: 7px;
  }
}

.food-block__list .ttl {
  display: block;
  font-size: 5rem;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: 600;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .food-block__list .ttl {
    font-size: 2.6rem;
    margin-bottom: 6px;
  }
}

.food-banner {
  position: relative;
}

.food-banner__img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.food-banner__img .slick-list {
  width: 100%;
}

.food-banner__img img {
  width: 100%;
  max-height: 900px;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.food-banner__note {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.55555;
  text-align: center;
  padding: 41px 15px 56px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 834px) {
  .food-banner__note {
    font-size: 1.4rem;
    padding: 15px 15px 26px;
    line-height: 1.75;
  }
}

.food-banner__note .ttl {
  display: block;
  text-align: center;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 18px;
  height: 0;
}

@media screen and (max-width: 834px) {
  .food-banner__note .ttl {
    font-size: 2.2rem;
    margin-bottom: 14px;
  }
}

.food-banner__note .ttl span {
  position: relative;
}

.food-banner__note .ttl span img {
  opacity: 0;
}

.food-banner__note .ttl span .ico {
  position: absolute;
}

.food-banner__note .ttl span .ico img {
  opacity: 1;
}

.food-banner__note .ttl span .ico-left {
  top: -143px;
  left: -200px;
}

@media screen and (max-width: 834px) {
  .food-banner__note .ttl span .ico-left {
    top: -110px;
    left: -84px;
    width: 107px;
  }
}

.food-banner__note .ttl span .ico-right {
  top: -149px;
  right: -213px;
}

@media screen and (max-width: 834px) {
  .food-banner__note .ttl span .ico-right {
    top: -99px;
    right: -90px;
    width: 77px;
  }
}

.food-banner__note p {
  margin-bottom: 28px;
}

@media screen and (max-width: 834px) {
  .food-banner__note p {
    margin-bottom: 23px;
  }
}

.food-banner__note-02 {
  background: rgba(255, 255, 255, 0.8);
  color: #404040;
}

.food-banner__ttl {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 40%;
  transform: translateY(-50%);
}

@media screen and (max-width: 834px) {
  .food-banner__ttl {
    top: 33%;
  }
}

.food-banner__dost {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.food-banner__dost .dost-banner {
  position: relative;
}

.food-banner__dost .slick-dots {
  position: static;
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-dots li {
    margin: 0 3px;
  }
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-dots li button:before {
    width: 9px;
    height: 9px;
  }
}

.food-banner__dost .slick-dots li.slick-active button:before {
  background: #404040;
}

.food-banner__dost .slick-dots li.slick-active button:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #404040;
  border-radius: 50%;
  position: absolute;
  left: 3px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-dots li.slick-active button:after {
    width: 5px;
    height: 5px;
    top: 2px;
    left: 0;
    transform: translateY(0);
  }
}

.food-banner__dost .slick-arrow {
  transform: translate(0, 0);
  top: 8px;
  width: 13px;
  height: 23px;
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-arrow {
    top: 9px;
    width: 9px;
    height: 17px;
  }
}

.food-banner__dost .slick-arrow:before {
  display: none !important;
}

.food-banner__dost .slick-arrow.slick-prev {
  background: url("../img/common/prev.png") no-repeat;
  background-size: 100%;
  left: -35px;
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-arrow.slick-prev {
    left: -25px;
  }
}

.food-banner__dost .slick-arrow.slick-next {
  background: url("../img/common/next.png") no-repeat;
  background-size: 100%;
  right: -35px;
}

@media screen and (max-width: 834px) {
  .food-banner__dost .slick-arrow.slick-next {
    right: -25px;
  }
}

.food-banner__dost-02 .slick-dots {
  position: static;
}

.food-banner__dost-02 .slick-dots li button:before {
  background: #404040;
}

.food-banner__dost-02 .slick-dots li.slick-active button:before {
  background: white;
}

.food-banner__dost-02 .slick-dots li.slick-active button:after {
  background: white;
}

.food-banner__dost-02 .slick-arrow.slick-prev {
  background: url("../img/common/prev-02.png") no-repeat;
  background-size: 100%;
}

.food-banner__dost-02 .slick-arrow.slick-next {
  background: url("../img/common/next-02.png") no-repeat;
  background-size: 100%;
}

.food-banner__dost-02 .slick-arrow.slick-disabled {
  pointer-events: none;
}

.food-banner-02 .food-banner__img img {
  max-height: 850px;
}

.show-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  opacity: 0;
  visibility: visible;
  transition: ease 0.5s;
}

.show-popup .popup-block {
  display: block;
  max-width: 1012px;
  position: relative;
  background: #fde6e5;
  padding: 50px;
}

@media screen and (max-width: 834px) {
  .show-popup .popup-block {
    padding: 25px 10px 10px;
  }
}

.show-popup .popup-block__close {
  cursor: pointer;
  width: 32px;
  position: absolute;
  top: 13px;
  right: 13px;
}

@media screen and (max-width: 834px) {
  .show-popup .popup-block__close {
    width: 16px;
    top: 5px;
    right: 5px;
  }
}

.show-popup .popup-block__img {
  display: none;
}

.show-popup .popup-block__img.active {
  display: block;
}

.show-popup .popup-block__img img {
  max-height: 80vh;
}

.show-popup.active {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.sec-mv-other{
	margin-bottom: -90px;
	@include mq(){
		margin-bottom: -47px;
	}
}
.sec-access{
	@include mq(){
		padding-bottom: 26px;
	}
	.bg-pink{
		position: relative;
		margin-bottom: 156px;
		@include mq(){
			padding-bottom: 12px;
			margin-bottom: 68px;
		}
		&:after{
			content: "";
			display: block;
			width: 100%;
			height: 7.4vw;
			position: absolute;
			bottom: -7.4vw;
			left: 0;
			background: url("../img/access/before-access.png") no-repeat center/cover;
			@include mq(){
				height: 30px;
				bottom: -30px;
				background: url("../img/access/before-access-sp.png") no-repeat center/cover;
			}
		}
	}
}

.access-ttl{
	
	&-sub{
		font-family: $font-mincho;
		@include font-size(30);
		font-weight: 600;
		margin-bottom: 50px;
		@include mq(){
			@include font-size(18);
			text-align: center;
			margin-bottom: 29px;
		}
	}
}

.access-block{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	@include mq(){
		padding: 0 30px;
	}
}

.list-item{
	margin-bottom: 55px;
	@include mq(){
		margin-bottom: 20px;
	}
	dl{
		margin-bottom: 50px;
		@include mq(){
			margin-bottom: 34px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		dt{
			@include font-size(20);
			font-weight: bold;
			margin-bottom: 5px;
			@include mq(){
				@include font-size(15);
			}
		}
		dd{
			@include font-size(20);
			line-height: 1.7;
			font-weight: 500;
			display: inline-block;
			border-top: 1px solid #404040;
			padding-top: 5px;
			@include palt();
			@include mq(){
				display: block;
				@include font-size(17);
				padding-top: 0;
			}
			span{
				@include font-size(16);
				font-weight: 500;
				display: block;
				@include mq(){
					@include font-size(15);
					margin-right: 25px;
					line-height: 1.75;
				}
			}
		}
	}
	.flex{
		display: flex;
		flex-wrap: wrap;
		dl{
			margin-right: 64px;
			@include mq(){
				margin-right: 50px;
			}
			&:last-of-type{
				margin-right: 0;
			}
		}
	}
	&--style02{
		@include mq(){
			padding: 0 15px;
		}
		dl{
			@include mq(){
				margin-bottom: 25px;
			}
			dd{
				display: flex;
				flex-wrap: wrap;
				@include mq(){
					padding-top: 5px;
				}
				ul{
					width: 50%;
					@include mq(){
						width: 100%;
						display: flex;
						flex-wrap: wrap;
					}
					&:last-of-type{
						padding-left: 35px;
						@include mq(){
							padding-left: 0;
						}
					}
					li{
						@include mq(){
							margin-right: 30px;
						}
						&:last-of-type{
							margin-right: 0;
						}
					}
				}
				p{
					margin-bottom: 40px;
					@include mq(){
						margin-bottom: 26px;
					}
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				a{
					color: #404040;
				}
			}
		}
	}
}

.map-block{
	margin-bottom: 56px;
	@include mq(){
		padding: 0 30px;
		margin-bottom: 20px;
	}
	iframe{
		width:  100%;
		height: 580px;
		@include mq(){
			width: 100%;
			height: 153px;
		}
	}
}

.btn-group{
	.btn-comp01{
		margin: 0 auto;
	}
}
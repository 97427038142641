.sec-mv-page{
	.mv-heading{
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		text-align: center;
		@include mq(){
			top: 16.5%;
		}
		.ttlcomp-01{
			margin-bottom: 26px;
			@include mq(){
				margin-bottom: 13px;
			}
			span{
				width: 120px;
				height: 120px;
				@include mq(){
					width: 69px;
					height: 69px;
					@include font-size(35);
				}
			}
		}
		p{
			font-family: $font-mincho;
			@include font-size(35);
			font-weight: 600;
			letter-spacing: 2px;
			@include mq(){
				@include font-size(22.5);
				letter-spacing: 1px;
			}
		}
		.ico{
			position: absolute;
			top: 58px;
			left: -66%;
			@include mq(){
				top: -25px;
				left: 50%;
				transform: translateX(43%);
			}
			img{
				height: auto;
				@include mq(md-min){
					width: 196px;
				}
			}
			&-kyo{
				top: 87px;
				left: auto;
				right: -113%;
				width: 130px;
				@include mq(){
					top: -28px;
					left: -78px;
					right: auto;
					transform: translateX(0);
				}
			}
			&-den{
				@include mq(){
					top: -34px;
					transform: translateX(88%);
				}
			}
		}
		.heading-inner{
			display: inline-block;
			position: relative;
		}
	}
	.img{
		img{
			width: 100%;
			height: 800px;
			@include ob();
			@include mq(){
				height: auto;
			}
		}
	}
}

.sec-hot-spring{
	background: #b9d1f1;
	.list-item{
		.item{
			padding-top: 70px;
			position: relative;
			@include mq(){
				padding-top: 32px;
			}
			.ico{
				position: absolute;
				top: 42px;
				right: 15.5%;
				z-index: 2;
				@include mq(){
					top: 176px;
					right: 38px;
				}
				&-den{
					top: auto;
					right: auto;
					bottom: -108px;
					left: 16.2%;
					z-index: 3;
					@include mq(){
						top: 13px;
						right: 24px;
						bottom: auto;
						left: auto;
					}
				}
			}
			&__heading{
				position: relative;
				z-index: 2;
				max-width: 730px;
				width: 100%;
				padding: 0 15px;
				margin: 0 auto -35px;
				@include mq(){
					margin-bottom: 8px;
				}
			}
			&__img{
				position: relative;
				z-index: 1;
				&:before{
					content: "";
					display: block;
					width: 100%;
					height: 7.4vw;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					background: url("../img/yu/before.png") no-repeat center/cover;
					@include mq(){
						height: 54px;
					}
				}
				&-other{
					img{
						width: 100%;
						max-height: 720px;
						@include ob;
						object-position: bottom;
					}
					&-02{
						img{
							max-height: 700px;
						}
					}
				}
			}
			&__info{
				background: #fff;
				padding-top: 65px;
				padding-bottom: 62px;
				@include mq(){
					padding-top: 27px;
					padding-bottom: 28px;
				}
				.ttlcomp-02{
					@include mq(){
						margin-bottom: 33px;
					}
				}
				.txt-basic{
					line-height: 1.6;
					@include mq(){
						@include font-size(12);
						line-height: 1.7;
					}
					p{
						margin-bottom: 28px;
						@include mq(){
							margin-bottom: 20px;
						}
						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}
			}
			&:nth-child(even){
				.ico{
					left: 11.8%;
					right: auto;
					top: -68px;
					@include mq(){
						left: 44px;
						right: auto;
						top: 186px;
					}
				}
			}
		}
	}
	
	.wp-inner{
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
		@include mq(){
			padding: 0 42px;
		}
	}
}
.z2{
	z-index: 2 !important;
	position: relative;
	margin-bottom: -6px !important;
}

body {
	color: #404040;
	font-family: $font-Yugothic;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	position: relative;
	overflow: hidden;
}


//header
#header{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	@include mq(){
		position: fixed;
	}
	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 0 15px;
		max-width: 100%;
	}
	@include mq(md-min){
		&.full{
			top: -100px;
			.logo{
				opacity: 0 !important;
				visibility: hidden;
				transition: all 0s ease;
			}
			.inner{
				max-width: 100%;
				padding: 0;
			}
			&.fixed{
				@include smooth-transition;
				position: fixed;
				top: 0;
			}
		}
	}
	.logo{
		width: 171px;
		display: block;
		position: relative;
		@include mq(){
			width: 112.5px;
			top: 5px;
		}
		.logo{
			&-01{

			}
			&-02{
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
	}
	.header{
		&-show{
			height: 100px;
			background: #fde6e5;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			@include mq(){
				height: 55px;
			}
		}
		&-tel{
			background: #404040;
			width: 280px;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-right: 1px;
			@include mq(){
				width: 55px;
				height: 55px;
			}
			img{
				width: 216.5px;
				@include mq(){
					width: 23px;
				}
			}
		}
		&-note{
			background: #404040;
			color: white;
			text-align: center;
			width: 100px;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-right: 1px;
			font-weight: 500;
			font-family: $font-mincho;
			@include font-size(16);
			@include mq(){
				@include font-size(14);
				width: 55px;
			}
		}
	}
	.show-menu{
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background: #e6e6e6;
		display: none;
		@include mq(){
			padding: 9px 0 0;
		}
		.item{
			position: relative;
			border-bottom: 1px solid white;
			&:last-child{
				border-bottom: none;
			}
			a{
				display: block;
				@include font-size(16.5);
				font-weight: 600;
				color: black;
				padding: 8px 30px;
				@include mq(){
					@include font-size(15);
					padding: 6px 16px;
				}
				small{
					@include font-size(12.5);
					@include mq(){
						@include font-size(12);
					}
				}
				&:hover{
					background: white;
					opacity: 1;
				}
			}
			&-last{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 12px 30px;
				@include mq(){
					padding: 8px 17px;
				}
				a{
					padding: 0;
					margin-right: 10px;
					&:hover{
						background: none;
						opacity: 0.7;
					}
				}
				.language{
					display: block;
					@include font-size(15);
					background: #404040;
					color: white;
					line-height: 1;
					padding: 9px 18px;
					margin-right: 17px;
					@include mq(){
						@include font-size(14);
						margin: 0 30px 15px 0;
					}
					&:hover{
						background: #404040;
						opacity: 0.7;
					}
				}
			}
			&.active{
				a{
					background: #fde6e5;
				}
			}
		}
	}
	.mobile-icon {
		background: #404040;
		width: 100px;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: relative;
		cursor: pointer;
		z-index: 12;
		@include mq(){
			width: 55px;
		}
		span{
			width: 30px;
			height: 2px;
			background: #fde6e5;
			position: relative;
			@include mq(){
				width: 22px;
			}
			&:before,&:after{
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background: #fde6e5;
				content: '';
				transition: transform 0.3s;
				border-radius: 3px;
			}
			&:before{
				transform: translateY(-250%);
				top: -5px;
				@include mq(){
					top: -3px;
				}
			}
			&:after{
				transform: translateY(250%);
				bottom: -5px;
				@include mq(){
					bottom: -3px;
				}
			}
		}
		&.mobile-close {
			background: #e6e6e6;
			span{
				background: none;
				&:before,&:after{
					width: 100%;
					background: #404040;
				}
				&:before {
					-webkit-transform: translateY(0) rotate(45deg);
					transform: translateY(0) rotate(45deg);
					top: 2px;
				}
				&:after {
					-webkit-transform: translateY(0) rotate(-45deg);
					transform: translateY(0) rotate(-45deg);
					bottom: -2px;
				}
			}
		}
	}
	&.top-page{
		top: -100px;
		transition: ease 1s;
		.logo{
			opacity: 0;
			z-index: 9;
			transition: ease 3s;
			&-01{
				opacity: 0;
				visibility: hidden;
			}
			&-02{
				opacity: 0;
				visibility: hidden;
				transition: ease 3.5s;
				transition-delay: 1.5s;
			}
		}
	}
}


.is-hello{
	&.active{
		.top-page{
			top: 0 !important;
			.logo{
				opacity: 1 !important;
				&-02{
					opacity: 1 !important;
					visibility: visible !important;
				}
			}
		}
		.sec-mv:after{
			opacity: 1 !important;
			visibility: visible !important;
		}
		.mv-list{
			.item{
				img{
					opacity: 1 !important;
				}
			}
		}
	}
}

//footer
#footer{
	background: #404040;
}

.social-ft{
	background: #fde6e5;
	padding: 78px 0 92px;
	@include mq(){
		padding: 38px 0 45px;
	}
	.list-social{
		max-width: 758px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		@include mq(sm){
			padding: 0 25px;
		}
		.item{
			width: 32.98%;
			@include mq(sm){
				width: 100%;
				max-width: 59.35%;
				margin: 0 auto 43px;
			}
			&:last-of-type{
				width: calc(100% - 32.98%);
				padding-left: 58px;
				@include mq(){
					padding-left: 25px;
				}
				@include mq(sm){
					max-width: 100%;
					width: 100%;
					padding-left: 0;
					margin-bottom: 0;
				}
			}
			&__ttl{
				font-family: $font-mincho;
				@include font-size(20);
				font-weight: 600;
				margin-bottom: 20px;
				@include mq(){
					@include font-size(15);
					margin-bottom: 10px;
				}
				@include mq(sm){
					text-align: center;
				}
			}
			&__content{
				.img{
					position: relative;
					iframe{
						width: 100% !important;
					}
					.note{
						width: 80px;
						height: 40px;
						border: 1px solid #ff0000;
						background: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						@include font-size(16);
						font-weight: bold;
						color: #ff0000;
						position: absolute;
						@include v-h-align();
						@include mq(){
							display: none;
						}
					}
				}
			}
			.flex{
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				margin: 0 -10px;
				.img{
					width: 33%;
					padding: 0 5px;
					@include mq(sm){
						width: 100%;
						padding: 10px 40px;
						max-width: 30em;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

.main-ft{
	padding: 54px 0 20px;
	position: relative;
	@include mq(){
		padding: 75px 0 20px;
	}
	.menu-ft{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 27px;
		@include mq(){
			padding: 0 25px;
			margin-bottom: 18px;
		}
		li{
			padding: 0 9px;
			position: relative;
			@include mq(){
				padding: 0 6px;
				margin-bottom: 7px;
			}
			&:after{
				content: "";
				display: block;
				height: 100%;
				width: 1px;
				background: #fde6e5;
				position: absolute;
				top: 0;
				right: 0;
				@include mq(){
					height: 12px;
					top: 2px;
				}
			}
			&:last-of-type{
				&:after{
					display: none;
				}
			}
			a{
				font-family: $font-mincho;
				@include font-size(15);
				font-weight: 500;
				color: #fde6e5;
				@include mq(){
					@include font-size(13);
				}
			}
		}
	}
	.list-sns{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
		@include mq(){
			margin-bottom: 26px;
		}
		li{
			margin-right: 10px;
			&:first-of-type{
				margin-right: 20px;
				@include mq(){
					margin-right: 10px;
				}
				a{
					font-family: $font-mincho;
					@include font-size(15);
					font-weight: bold;
					width: 90px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #fde6e5;
					color: #404040;
					@include mq(){
						width: 70px;
						height: 22px;
						@include font-size(14);
					}
				}
			}
		}
	}
	.ico-ft{
		position: absolute;
		top: -80px;
		right: 0;
		transform: translateX(100%);
		@include mq(){
			top: -112px;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		}
	}
	.inner{
		text-align: center;
	}
	.ft-wp{
		display: inline-block;
		position: relative;
	}
}

.info-ft{
	text-align: center;
	font-family: $font-mincho;
	.tel{
		@include font-size(16);
		color: #fde6e5;
		margin-bottom: 14px;
		@include mq(){
			@include font-size(12);
		}
		a{
			color: #fde6e5;
			@include font-size(28);
			line-height: 1;
			letter-spacing: 1px;
			display: inline-block;
			margin: 0 6px;
			@include mq(){
				@include font-size(26);
			}
		}
	}
	.address{
		@include font-size(14);
		font-weight: 500;
		color: #fde6e5;
		line-height: 1.75;
		margin-bottom: 10px;
		letter-spacing: 1px;
		@include mq(){
			@include font-size(12);
			margin-bottom: 17px;
		}
	}
	.copy-right{
		@include font-size(12);
		font-weight: 500;
		display: block;
		color: #fde6e5;
		@include mq(){
			@include font-size(10);
		}
	}
}



.cdsROW.narrow{
	width: 100% !important;
}


.scrolled-down{
	#header{
		@include mq(){
			.logo{
				opacity: 0 !important;
				visibility: hidden !important;
				z-index: -1;
				transition-delay: 0.3s;
			}
		}
	}
}
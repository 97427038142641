.sec-contact{
	padding-bottom: 97px;
	@include mq(){
		padding-bottom: 50px;
	}
}
.main-form{
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	@include mq(){
		max-width: 290px;
	}
	.txt-intro{
		@include font-size(20);
		font-weight: 500;
		margin-bottom: 40px;
		@include mq(){
			@include font-size(17);
			margin-bottom: 44px;
		}
	}
	form{
		dl{
			margin-bottom: 47px;
			@include mq(){
				margin-bottom: 23px;
			}
			&:last-of-type{
				margin-bottom: 98px;
				@include mq(){
					margin-bottom: 44px;
				}
			}
			dt{
				font-family: $font-mincho;
				@include font-size(20);
				font-weight: 600;
				margin-bottom: 3px;
				@include mq(){
					@include font-size(17);
					margin-bottom: 5px;
				}
			}
			dd{
				.form{
					&-input{
						width: 100%;
						height: 40px;
						background: #fff;
						outline: 0;
						border: none;
						appearance: none;
						padding: 0 15px;
						@include font-size(20);
						@include mq(){
							height: 25px;
							@include font-size(17);
						}
					}
					&-textarea{
						width: 100%;
						height: 295px;
						background: #fff;
						outline: 0;
						border: none;
						appearance: none;
						padding: 15px;
						@include font-size(20);
						@include mq(){
							height: 225px;
							@include font-size(17);
						}
					}
				}
			}
		}
	}
	.btn-group{
		display: flex;
		justify-content: center;
		max-width: 360px;
		margin: 0 auto;
		.btn-submit{
			@include mq(){
				margin: 0 10px;
			}
		}
	}
}


.form-confirm{
	dl{
		dd{
			font-family: $font-mincho;
			@include font-size(20);
			font-weight: 600;
			@include mq(){
				@include font-size(17);
			}
		}
	}
}


.form-thanks{
	.ttlcomp-04{
		margin-bottom: 110px;
		@include mq(){
			margin-bottom: 75px;
		}
	}
	.thanks-content{
		@include palt();
		font-family: $font-mincho;
		@include font-size(20);
		font-weight: 600;
		text-align: center;
		@include mq(){
			@include font-size(17);
		}
	}
}

.mv{
	&-list{
		.item{
			transition: opacity 4000ms linear 0s !important;
			&.slick-active{
				img{
					opacity: 1;
				}
			}
		}
		img{
			width: 100%;
			height: 100vh;
			@include ob;
			opacity: 0;
			@include mq(){
				height: 68vh;
			}
		}
	}
}


.about{
	&-block{
		display: block;
		max-width: 700px;
		margin: 0 auto;
		position: relative;
		@include mq(){
			padding: 125px 0 0;
		}
		.item{
			position: absolute;
			&-01{
				top: -148px;
				left: -33px;
				opacity: 0;
				visibility: hidden;
				@include mq(){
					display: none;
				}
				&.active{
					opacity: 1;
					visibility: visible;
				}
			}
			&-02{
				top: 200px;
				left: -107px;
				@include mq(){
					display: none;
				}
			}
			&-03{
				top: -195px;
				right: -150px;
				opacity: 0;
				visibility: hidden;
				@include mq(){
					display: none;
				}
				&.active{
					opacity: 1;
					visibility: visible;
				}
			}
			&-04{
				display: block;
				max-width: 295px;
				margin: 0 auto;
				top: -40px;
				left: 0;
				right: 0;
			}
		}
		&__txt{
			padding: 0 0 0 118px;
			text-align: justify;
			margin-bottom: 38px;
			@include palt;
			@include mq(){
				padding: 0 26px;
				@include font-size(12);
				line-height: 1.6666666666;
			}
			.ttl{
				display: block;
				@include font-size(32);
				font-family: $font-mincho;
				font-weight: 600;
				margin-bottom: 22px;
				@include mq(){
					text-align: center;
					@include font-size(23);
					line-height: 1.3;
					margin-bottom: 12px;
				}
			}
			a{
				color: #404040;
				text-decoration: underline;
				font-weight: bold;
			}
			p{
				margin-bottom: 33px;
				letter-spacing: 0.5px;
				@include mq(){
					margin-bottom: 20px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		&__note{
			background: white;
			display: block;
// 			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 30px 20px;
			@include mq(){
				@include font-size(12);
				padding: 14px 20px;
			}
			.ttl{
				margin: 0 0 10px 20px;
				display: inline-block;
				text-align: center;
				width: 110px;
				@include font-size(18);
				font-family: $font-mincho;
				background: #404040;
				color: white;
				font-weight: 600;
				@include mq(){
					@include font-size(14);
					margin: 0 auto 16px;
					padding: 6px;
					min-width: 85px;
					width: auto;
				}
			}
			p{
				width: calc(100% - 110px);
				padding: 0 0 0 20px;
				letter-spacing: 0.5px;
				@include palt;
				@include mq(){
					width: 100%;
					padding: 0;
					line-height: 1.45833333;
				}
				span,strong{
					display: block;
					@include font-size(13);
					text-decoration: underline;
					font-weight: bold;
					margin-bottom: 3px;
				}
				
			}
		}
		.detail-btn{
			margin-top: 10px;
			max-width: 200px;
			height: 40px;
			@include font-size(20);
			.detail{
				margin-right: 40px;
				@include mq(){
					margin-right: 0;
				}			
			}
		}
	}
}

.concept{
	&-item{
		.item{
			position: relative;
			a{
				display: block;
				&:hover{
					opacity: 1;
				}
			}
		}
		&__banner{
			position: relative;
			@include mq(){
				padding: 0 0 100px;
			}
			img{
				width: 100%;
			}
		}
		&__txt{
			text-align: center;
			font-weight: 500;
			color: #231815;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			padding: 66px 0 60px;
			@include mq(){
				color: black;
				@include font-size(13);
				line-height: 1.9230;
				padding: 33px 0 31px;
			}
			&:before{
				content: "";
				position: absolute;
				bottom: calc(100% - 1px);
				left: 0;
				right: 0;
				height: 20vw;
			}
			&#item{
				&-01{
					//@include mq(){
					//	color: white;
					//}
					&:before{
						background: url("../img/index/bf-orange.png")no-repeat;
						background-size: 100%;
						background-position: bottom center;
						@include mq(){
							background: url("../img/index/bf-orange-sp.png")no-repeat;
							background-size: 100%;
							background-position: bottom center;
						}
					}
					.ttlcomp-01{
						.note{
							right: 0;
							@include mq(){
								right: 15%;
								width: 75px;
							}
						}
					}
				}
				&-02{
					&:before{
						background: url("../img/index/bf-light-blue.png")no-repeat;
						background-size: 100%;
						background-position: bottom center;
						@include mq(){
							background: url("../img/index/bf-light-blue-sp.png")no-repeat;
							background-size: 100%;
							background-position: bottom center;
						}
					}
					.ttlcomp-01{
						.note{
							bottom: 10px;
							left: -23px;
							width: 167px;
							@include mq(){
								width: 94px;
								left: 10%;
								bottom: 0;
							}
						}
					}
				}
				&-03{
					&:before{
						background: url("../img/index/bf-yellow.png")no-repeat;
						background-size: 100%;
						background-position: bottom center;
						@include mq(){
							background: url("../img/index/bf-yellow-sp.png")no-repeat;
							background-size: 100%;
							background-position: bottom center;
						}
					}
					.ttlcomp-01{
						.note{
							bottom: 0;
							right: -29px;
							@include mq(){
								right: 11%;
								width: 100px;
							}
						}
					}
				}
				&-04{
					@include mq(){
						padding: 46px 0 46px;
					}
					&:before{
						background: url("../img/index/bf-pink.png")no-repeat;
						background-size: 100%;
						background-position: bottom center;
						@include mq(){
							background: url("../img/index/bf-pink-sp.png")no-repeat;
							background-size: 100%;
							background-position: bottom center;
						}
					}
					.ttlcomp-01{
						.note{
							left: 32px;
							@include mq(){
								left: 21%;
								width: 58px;
								bottom: -13px;
							}
						}
					}
				}
			}
			.ttlcomp-01{
				width: 520px;
				position: absolute;
				top: -81px;
				left: 0;
				right: 0;
				margin: 0 auto;
				@include mq(){
					width: 100%;
					max-width: 375px;
					top: -50px;
				}
				.note{
					background: none;
					width: auto;
					height: auto;
					position: absolute;
					bottom: 0;
				}
			}

		}
	}
}


.sec{
	&-mv{
		width: 100%;
		height: 100vh;
		@include mq(){
			height: 68vh;
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: -70px;
			background: url("../img/index/bg-mv.png")no-repeat;
			background-size: cover;
			background-position: bottom center;

			@include mq(md-min){
				opacity: 0;
				visibility: hidden;
				transition: ease 3.5s;
				transition-delay: 1.5s;
			}
			@include mq(){
				bottom: -15px;
				background: url("../img/index/bg-mv-sp.png")no-repeat;
				background-size: cover;
				background-position: bottom center;
				left: -3px;
				right: -3px;
			}
		}
	}
	&-about{
		background: #fde6e5;
		padding: 95px 0 47px;
		@include mq(){
			padding: 0 0 51px;
		}
	}
	&-concept{

	}
}

.list-post{
	margin-left: 20px;
	@include mq(){
		margin-left: 0;
	}
	li{
		margin-bottom: 10px;
		@include mq(){
			margin-bottom: 7px;
		}
		a{
			display: flex;
			span{
				&.date{
					width: 130px;
					@include mq(){
						width: 80px;
					}
				}
				&.txt{
					width: calc(100% - 130px);
					padding-top: 5px;
					display: block;
					font-size: 1.3rem;
					text-decoration: underline;
					font-weight: bold;
					@include mq(){
						width: calc(100% - 80px);
						padding-top: 0;
					}
				}
			}
		}
	}
}

.link-detail{
	margin-left: 20px;
	text-decoration: underline;
	@include font-size(13);
	font-weight: bold;
	@include mq(){
		margin-left: 0;
	}
}